import React, { useState } from 'react';
import "../ContactCard/ContactCard.css";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from 'react-input-mask';
import main_bg_banner_video from "../../video/knightingale_landingpage_main_banner.mp4";
import knightingale_main_banner_mobile from "../../video/knightingale_landingpage_main_banner_mobile.mp4";

import knightingale_main_banner_mobile_thumbnail from "../../img/Navabar/knightingale_main_banner_mobile_thumbnail.jpg";
import knightingale_landingpage_main_banner_thumbnail from "../../img/Navabar/knightingale_landingpage_main_banner_thumbnail.jpg";
const ContactCard = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [contactError, setContactError] = useState('');
  const [userType, setUserType] = useState('');
  const [otherUserType, setOtherUserType] = useState('');
  const [interests, setInterests] = useState([]);
  const [otherInterest, setOtherInterest] = useState('');
  const [smsConsent, setSmsConsent] = useState(false);
  const [privacyConsent, setPrivacyConsent] = useState(false);
  const [country, setCountry] = useState('');
  const [countryError, setCountryError] = useState('');
  const [privacyConsentError, setPrivacyConsentError] = useState('');
  const [userTypeError, setUserTypeError] = useState('');
  const [enable, setEnable] = useState('');

  const handleInterestChange = (event) => {
    const { value } = event.target;
    setInterests((prev) => {
      if (value === "Other" && !prev.includes("Other")) return [...prev, value];
      return prev.includes(value) ? prev.filter((interest) => interest !== value) : [...prev, value];
    });
  };

  const countries = [
    "United States", "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua & Deps",
    "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain",
    "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia",
    "Bosnia Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina", "Burundi",
    "Cambodia", "Cameroon", "Canada", "Cape Verde", "Central African Rep", "Chad", "Chile",
    "China", "Colombia", "Comoros", "Congo", "Congo {Democratic Rep}", "Costa Rica", "Croatia",
    "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
    "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia",
    "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana",
    "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras",
    "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland {Republic}", "Israel",
    "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati",
    "Korea North", "Korea South", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon",
    "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macedonia",
    "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia",
    "Montenegro", "Morocco", "Mozambique", "Myanmar, {Burma}", "Namibia", "Nauru", "Nepal",
    "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan",
    "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland",
    "Portugal", "Qatar", "Romania", "Russian Federation", "Rwanda", "St Kitts & Nevis",
    "St Lucia", "Saint Vincent & the Grenadines", "Samoa", "San Marino", "Sao Tome & Principe",
    "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia",
    "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka",
    "Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan",
    "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad & Tobago", "Tunisia", "Turkey",
    "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia",
    "Zimbabwe"
  ];


  const validateForm = () => {
    let isValid = true;

    // Name validation
    if (name.trim() === '') {
      setNameError('Please enter your name.');
      isValid = false;
    } else {
      setNameError('');
    }

    // Email validation
    if (email.trim() === '' || !validateEmail(email)) {
      setEmailError('Please enter a valid email.');
      isValid = false;
    } else {
      setEmailError('');
    }

    // Country validation
    if (country.trim() === '') {
      setCountryError('Please select your country.');
      isValid = false;
    } else {
      setCountryError('');
    }

    // User Type validation
    if (userType.trim() === '') {
      setUserTypeError('Please select an option for signing up as.');
      isValid = false;
    } else {
      setUserTypeError('');
    }

    // Contact validation
    if (contact.trim() === '' || !validateContact(contact)) {
      setContactError('Please enter a valid contact number.');
      isValid = false;
    } else {
      setContactError('');
    }

    // Privacy Consent validation
    if (!privacyConsent) {
      setPrivacyConsentError('You must agree to the privacy terms.');
      isValid = false;
    } else {
      setPrivacyConsentError('');
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = {
        name,
        email,
        tel: contact,
        country,
        userType,
        interests,
        otherInterest,
        smsConsent,
        privacyConsent,
      };

      try {
        const response = await fetch('https://chatdbd.timetravel.vip/contact_form_handler.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        const result = await response.json();
        console.log(result.message);

        // Clear the form fields after successful submission
        setName('');
        setEmail('');
        setContact('');
        setCountry('');
        setUserType('');
        setOtherUserType('');
        setInterests([]);
        setOtherInterest('');
        setSmsConsent(false);
        setPrivacyConsent(false);
      } catch (error) {
        console.error('Error sending the form data:', error);
      }
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateContact = (contact) => {
    const re = /^\+?\d+$/;
    return re.test(contact);
  };


  return (
    <div className='page-limitation-wrapper'>
      <div className='page-video-wrapper'>
        <div className='page-video'>
          <div className='video-banner'>
            <video className='video-banner-desktop ' poster={knightingale_landingpage_main_banner_thumbnail} src={main_bg_banner_video} playsInline loop autoPlay muted />
            <video className='video-banner-mobile' poster={knightingale_main_banner_mobile_thumbnail} src={knightingale_main_banner_mobile} playsInline loop autoPlay muted />
          </div>
        </div>
      </div>
      <div className="page-limitation">
        <div className="top-conatiner">
          <h1>Contact</h1>
        </div>
        <div className="mid-container">
          <div className="form-card-container">
            <form method="POST" action="http://httpbin.org/post" acceptCharset="UTF-8" className="form-inner-container" onSubmit={handleSubmit}>
              {/* Other Form Fields */}
              <div className="form-conatiner">
                <label className='form-label'>Name</label>
                <div className="input-comman-container">
                  <input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} type="text" />
                </div>
                <div className="error-message">{nameError}</div>
              </div>

              <div className="form-conatiner">
                <label className='form-label'>Email</label>
                <div className="input-comman-container">
                  <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
                </div>
                <div className="error-message">{emailError}</div>
              </div>

              {/* Country Selector */}
              <div className="form-conatiner">
                <label className='form-label'>Country</label>
                <div className="input-comman-container">
                  <select className="" value={country} onChange={(e) => setCountry(e.target.value)}>
                    {countries.map((country) => (
                      <option key={country} value={country}>{country}</option>
                    ))}
                  </select>
                </div>
                <div className="error-message">{countryError}</div>

              </div>

              {/* User Type Selection */}
              <div className="form-conatiner input-container">
                <label className='form-label'>Signing up as:</label>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className='input-comman-container'>
                    <label className='select-label'>
                      <input className='select-input' type="radio" value="Individual" checked={userType === 'Individual'} onChange={(e) => setUserType(e.target.value)} />
                      <span>Individual</span>
                    </label>
                  </div>
                  <div className='input-comman-container'>
                    <label className='select-label' >
                      <input className='select-input' type="radio" value="Business/Organization" checked={userType === 'Business/Organization'} onChange={(e) => setUserType(e.target.value)} />
                      <span>Business/Organization</span>
                    </label>
                  </div>
                  <div className='input-comman-container'>
                    <label className='select-label' >
                      <input className='select-input' type="radio" value="Potential Partner" checked={userType === 'Potential Partner'} onChange={(e) => setUserType(e.target.value)} />
                      <span>Potential Partner</span>
                    </label>
                  </div>
                  <div className='input-comman-container'>
                    <label className='select-label' >
                      <input type="radio" className='select-input' value="Investor" checked={userType === 'Investor'} onChange={(e) => setUserType(e.target.value)} />
                      <span>Investor</span>
                    </label>
                  </div>
                  <div className='input-comman-container'>
                    <label className='select-label' >
                      <input className='select-input' type="radio" value="Other" checked={userType === 'Other'} onChange={(e) => setUserType(e.target.value)} />
                      <span>Other</span>
                    </label>
                  </div>

                </div>
                {userType === 'Other' && (
                  <input type="text" placeholder="Please specify" value={otherUserType} onChange={(e) => setOtherUserType(e.target.value)} className="input-conatiner" />
                )}
                <div className="error-message">{userTypeError}</div>
              </div>

              {/* Interest Selection */}
              <div className="form-conatiner">
                <label className='form-label'>I'm interested in: (Select all that apply)</label>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className='input-comman-container'>
                    <label className='select-label' >
                      <input className='select-input' type="checkbox" value="Enhancing personal wellness" onChange={handleInterestChange} />
                      <span>Enhancing personal wellness</span>
                    </label>
                  </div>
                  <div className='input-comman-container'>
                    <label className='select-label' >
                      <input className='select-input' type="checkbox" value="Implementing corporate wellness programs" onChange={handleInterestChange} />
                      <span>Implementing corporate wellness programs</span>
                    </label>
                  </div>
                  <div className='input-comman-container'>
                    <label className='select-label' >
                      <input className='select-input' type="checkbox" value="Exploring health and wellness technology" onChange={handleInterestChange} />
                      <span>Exploring health and wellness technology</span>
                    </label>
                  </div>
                  <div className='input-comman-container'>
                    <label className='select-label' >
                      <input className='select-input' type="checkbox" value="Partner with or invest in Knightingale" onChange={handleInterestChange} />
                      <span>Partner with or invest in Knightingale</span>
                    </label>
                  </div>
                  <div className='input-comman-container'>
                    <label className='select-label' >
                      <input className='select-input' type="checkbox" value="Other" onChange={handleInterestChange} />
                      <span>Other</span>
                    </label>
                  </div>
                </div>
                {interests.includes("Other") && (
                  <input type="text" placeholder="Please specify" value={otherInterest} onChange={(e) => setOtherInterest(e.target.value)} className="input-conatiner" />
                )}
              </div>

              <div className="form-conatiner">
                <label className='form-label'>Contact No</label>
                <div className="input-comman-container">
                  <InputMask mask="99999999999999" maskChar="" placeholder="Contact No" value={contact} onChange={(e) => setContact(e.target.value)} />
                </div>
                <div className="error-message">{contactError}</div>
              </div>

              {/* SMS Consent Checkbox */}
              <div className="form-conatiner">
                <label className="privacy-note  form-label">
                  By checking this box, I consent to receive wellness-related SMS messages to my phone number from Knightingale AI.
                </label>
                <label className='cmn-wrp-lbl'>
                  <input type="checkbox" checked={smsConsent} onChange={(e) => setSmsConsent(e.target.checked)} />
                  <span>I agree to receive SMS messages.</span>
                </label>
              </div>

              {/* Privacy Consent Checkbox */}
              <div className="form-conatiner">
                <label className="privacy-note form-label">
                  By submitting this form, you consent to Knightingale using the information provided here to process your early access request and to communicate with you about the program. Your details will be kept confidential and will not be shared with third parties. We respect your privacy and are committed to protecting your personal information.
                </label>
                <label className='cmn-wrp-lbl'>
                  <input type="checkbox" checked={privacyConsent} onChange={(e) => setPrivacyConsent(e.target.checked)} />
                  <span>I agree to the privacy terms.</span>
                </label>
                <div className="error-message">{privacyConsentError}</div>
              </div>

              <div className="form-conatiner">
                <ReCAPTCHA sitekey="6LflhJgpAAAAAG_TkmVx7i13s0NoZQwp-WZCxfR-" onChange={() => setEnable(true)} />
              </div>

              <div className="form-btn-group form-conatiner">
                <input className="form-submit" type="submit" value="Submit" disabled={!enable} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
