import React from 'react'
import "../WhitepaperCard/WhitepaperCard.css";


const WhitepaperCard = () => {
  return (
    <div>
        <div className="site-main-body-conatiner">
    <div className="site-inner-container">
        <div className="main-section-container white-paper-sestion">
            <div className="white-page-bg-image-conatiner">
               
                <img className="white-gb-image" src="https://eternmeta.com/assets/img/whitepaper_page_banner.jpg" alt=""/>
                <img className="white-mobile-gb-image" src="https://eternmeta.com/assets/img/whitepaper_page_mobile_banner.jpg" alt=""/>
            </div>
            <div className="white-paper-main-conatiner table-of-contnet-kps">
                <div className="white-paper-card top-section">
                    <div className="white-paper-card-header">
                        <h2 className="main-topic">Etern Whitepaper </h2>
                        <h4>Table of Contents</h4>
                    </div>
                    <div className="white-paper-card-body table-of-content">
                        <ol>
                            <li>
                                <a href="#Whywearehere">Why we are here</a>
                            </li>
                            <li>
                                <a href="#EternAvatarztheWeb3Identity">Etern Avatarz, the Web3 Identity</a>
                            </li>
                            <ul>
                                <li>
                                    <a href="#twoone">2.1 Player First Protocol</a>
                                </li>
                                <li>
                                    <a href="#twotwo">2.2 Mind-Avatar Connection</a>
                                </li>
                                <li>
                                    <a href="#twothree">2.3 Metaverse Passport</a>
                                </li>
                            </ul>
                            <li>
                                <a href="#MMORPM">MMORPM, the Role Playing Metaverse</a>
                            </li>
                            <ul>
                                <li>
                                    <a href="#threeone">3.1 Identity-Centric MMORPM</a>
                                </li>
                                <li>
                                    <a href="#threetwo">3.2 Gamified Social Metaverse (GSM)</a>
                                </li>
                                <li>
                                    <a href="#threethree">3.3 The Teleport Network</a>
                                </li>
                            </ul>
                            <li>
                                <a href="#Web3powered">Web3-powered Social Gameplay</a>
                            </li>
                            <ul>
                                <li>
                                    <a href="#fourone">4.1 Player-Owned Economy</a>
                                </li>
                                <li>
                                    <a href="#fourtwo">4.2 Leaderboardz and Quantumz</a>
                                </li>
                                <li>
                                    <a href="#fourthree">4.3 Leveling up (to infinity)</a>
                                </li>
                            </ul>
                            <li>
                                <a href="#EternMetaaLIVEEcosystem">EternMeta, a LIVE Ecosystem</a>
                            </li>
                            <ul>
                                <li>
                                    <a href="#fiveone">5.1 Planet Etern</a>
                                </li>
                                <li>
                                    <a href="#fivetwo">5.2 Habitatz and Worldz</a>
                                </li>
                                <li>
                                    <a href="#fivethree">5.3 - United NFTs (UN)</a>
                                </li>
                            </ul>
                            <li>
                                <a href="#TheMetaverseLaunchpad">The Metaverse Launchpad</a>
                            </li>
                            <ul>
                                <li>
                                    <a href="#sixone">6.1 Development of Landz</a>
                                </li>
                                <li>
                                    <a href="#sixtwo">6.2. Development Creditz</a>
                                </li>
                                <li>
                                    <a href="#sixthree">6.3 Space-Time Builder</a>
                                </li>
                            </ul>
                            <li>
                                <a href="#Whatwebelievein">What we believe in</a>
                            </li>
                            <ul>
                                <li>
                                    <a href="#sevenone">7.1 Core Values</a>
                                </li>
                                <li>
                                    <a href="#seventwo">7.2 Guiding Principles</a>
                                </li>
                            </ul>
                            <li>
                                <a href="#TheVision">The Vision</a>
                            </li>
                            <ul>
                                <li>
                                    <a href="#eightone">8.1 The birth of a Virtual Human Race</a>
                                </li>
                                <li>
                                    <a href="#eighttwo">8.2 Establishing Web3 Consciousness</a>
                                </li>
                                <li>
                                    <a href="#eightthree">8.3 Envisioning Digital Immortality</a>
                                </li>
                            </ul>
                            <li>
                                <a href="#Terminology">Terminology</a>
                            </li>
                            <li>
                                <a href="#Disclaimer">Disclaimer</a>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="Whywearehere">1. Why we are here</h2>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Etern Metaverse is a one-of-a-kind web3 ecosystem brought to you by DejaVu Quest,
                            Inc. This
                            is the first metaverse of its kind, bringing together a diverse community of NFT
                            holders on a
                            single platform, transforming the NFTs into life-like Avatarz inside an immersively
                            multiplayer
                            metaverse experience.
                        </p>
                        <p> We believe that the metaverse is the next step of evolution for mankind. Virtual
                            identities will
                            become an extension of the physical self of a human to function within the virtual
                            realms.</p>

                        <p>
                            The inevitable evolution of pre-metaverse PFP profiles into 4D live web3 identities
                            will be the
                            foundation of Etern as the pioneering identity-based metaverse. The closest visual
                            of this
                            vision is “The Oasis” in Spielberg’s sci-fi fantasy, “Ready Player One.”
                        </p>

                        <p>
                            Etern is purpose-built to become a Role Playing MMO Metaverse platform designed to
                            bring
                            together the NFT communities within the web3 space. While developing a metaverse
                            which is
                            identity-centric, Etern offers a limitless platform where Playerz will socialize in
                            a gamified,
                            immersive and safe environment.
                        </p>

                        <p>
                            Etern values unity, inclusivity, and quality for its Playerz, making it highly
                            player-centric and
                            user-friendly. We believe in the transformative power of web3 and the metaverse, to
                            create
                            fantasy-like experiences, heartfelt engagement and authentic transactions, a
                            combination
                            thought to be a utopian dream in web2.
                        </p>

                        <p>
                            Etern unites the community within a virtual reality, based on the concept of
                            decentralization.
                            The emphasis on inclusivity and diversity is a key element of Etern’s Metaverse
                            Ecosystem.
                            The platform welcomes all NFT holders, “A metaverse for all”, and encourages a
                            diverse range
                            of creators, developers and artists to participate in contributing to the next step
                            of its evolution.
                            We envision a truly interactive ecosystem “ For the Playerz, by the Playerz.”
                        </p>

                        <p>
                            Etern also places a high value on the Player experience. The platform is intended to
                            be intuitive
                            and simple to use, with a streamlined yet entertaining interface. This makes it more
                            accessible
                            to Playerz, facilitating user interaction and providing an effortless metaverse
                            experience.
                        </p>

                        <p>
                            The highlights of the Etern Metaverse experience are our community, innovation, and
                            a steady
                            pragmatic lookout for evolution. The inspiration of Etern embodies the concept of
                            “Action
                            before Words” a core value we hold as a part of the growth mindset aiming to make an
                            authentic mark in the upcoming meta-web3 revolution. As the pioneer of bringing 4D
                            NFT
                            Avatarz to life within a fully functioning metaverse MVP, Etern stands on the
                            groundwork of
                            long-term values and the potential to revolutionize the way we think about digital
                            ownership
                            and value.
                        </p>

                        <p>
                            To manifest a truly functional, authentic, and distinctive metaverse, we have
                            developed the
                            following fundamental concepts that form the foundations of the Etern Ecosystem.
                        </p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-body">
                        <h2>Player First Protocol (P1P):
                        </h2>
                        <p>We prioritize the Playerz above all. With the Player at the center of the universe,
                            the Player
                            First Protocol functions as an essential component to power the growth of the
                            metaverse. From
                            Playerz to Worldz, all of Etern's experiences are incorporated and highly
                            personalized with this
                            protocol.</p>
                        <h2>Metaverse Passport:</h2>
                        <p>A Metaverse Passport is the Player’s Avatar that functions both as a token-gated
                            entry to
                            numerous Etern Worldz and also as the Player’s identity within the metaverse. The
                            Etern
                            Metaverse's innovative token gating standards and access control give Playerz a
                            singular
                            experience in a safe, secure, and fully interoperable web3 planet.</p>
                        <h2>Teleport Network:</h2>
                        <p>A system of interdimensional transportation called the Teleport Network of the Etern
                            Metaverse
                            enables Playerz to move around and between Virtual Worldz. As a supersonic digital
                            subway
                            system, Teleport Stations, holding one or more Tele-portals, are placed at critical
                            locations.</p>
                        <h2>MMORPM:</h2>
                        <p>Etern's MMORPM (Massively Multiplayer Online Role Playing Metaverse) builds on
                            everything
                            you already know and love about MMORPGs and pushes it further by including web3
                            elements
                            while offering a more immersive, social environment to explore. Playerz can design
                            their own
                            stories and experiences in the metaverse, as this concept allows them to power a
                            very
                            interactive, gamified, social experience within the Etern Metaverse.</p>
                        <h2>United NFTs (UN): </h2>
                        <p>In an effort to provide all NFT initiatives with a uniform and equal basis in the
                            Web3
                            environment, Etern initiated a united frontier of all NFTs, i.e. United NFTs.
                            Depending on
                            individual preferences, we foster diversity while maintaining the exclusivity of NFT
                            subcultures.
                            Our top objective is to create a united, friendly environment that encourages
                            creativity and
                            cooperation.</p>
                        <h2>Web3 Consciousness:</h2>
                        <p>Being a web3-conscious Player entails having a deep awareness of the Playerz in their
                            virtual
                            environment. This helps you empathize with other Playerz and be aware of how your
                            actions
                            might impact them. In order to encourage people to think critically and explore new
                            ideas, our
                            metaverse is designed to be intellectually stimulating. As a place that would truly
                            practice web3
                            consciousness, Etern is committed to creating an environment where everyone feels
                            like they
                            belong.</p>
                        <h2>Digital Immortality:</h2>
                        <p>In a world where people are virtual, Etern will present a new form of
                            identity-centric virtual
                            beings. Homo Eternalz, the digital human race of Etern that resides in this new
                            civilization, has
                            no physical bounds and dwells in the virtual world. The long-term goal of Etern is
                            to construct
                            a virtual world where Playerz can develop eternal identities that are not confined
                            by physical
                            boundaries. Etern’s grand vision is to bring the idea of digital immortality to
                            life, by allowing
                            Playerz to virtually preserve their online identities leaving an eternal legacy in
                            the web3 space.
                        </p>
                        <h2>Metaverse Launchpad:</h2>
                        <p>The Metaverse Launchpad program is Etern’s support system for the Landlordz to build
                            Worldz
                            on the Landz they own. It is a virtual accelerator for your metaverse ideas to come
                            to life. The
                            Etern ecosystem is decentralized, with Landlordz having exclusive access to Landz,
                            Assetz
                            and Worldz they host. There are primarily three ways to build on Landz:
                            Building/importing
                            your own Assetz, utilizing Development Creditz, and later using the Space-Time
                            builder
                            (Etern’s upcoming DIY world builder solution). </p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="EternAvatarztheWeb3Identity">2. Etern Avatarz, the Web3 Identity</h2>
                        <h3 id="twoone">2.1 Player First Protocol</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Player First Protocol (P1P) is one of the primary characteristics of the Etern
                            Metaverse. We
                            believe that in order to create a truly immersive experience, the Player must be at
                            the center
                            of our universe.</p>
                        <p>The core unit of Etern is your exclusive virtual web3 identity. This identity is
                            formed when you
                            mint your own customized 4D Avatarz within the blockchain of the Etern Metaverse.
                            You'll be
                            able to own a one-of-a-kind virtual persona that will go beyond the 3D looks into a
                            cutting-edge
                            personality with unique traits.</p>

                        <p>This identity will be yours and yours alone, enabling you to live, explore, play and
                            interact with
                            other Players inside a massively multiplayer gamified environment. It also allows
                            you to create
                            your own content throughout our platform creating long-term utility for your Avatar.
                        </p>

                        <p>The Player First Protocol (P1P) helps us to focus on creating highly individualized
                            experiences
                            for all Playerz on the Etern Metaverse. Everything from Habitatz and Landz to Worldz
                            is
                            designed to be personalized to you and well integrated into the metaverse ecosystem.
                        </p>

                        <p>The heart of this remarkable ecosystem is you, the Player. From the 3D body of your
                            LiveAvatarz to the custom-developed 4D traits, you’re the one who determines how
                            you look, feel
                            and act within this new personalized world of possibilities.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="twotwo">2.2 Mind-Avatar Connection</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p> The Etern Metaverse is a virtual playground where Avatarz becomes the digital
                            extension of
                            Players’ identities and personalities. Connecting with their unique Avatarz, Playerz
                            will fully
                            immerse their minds in the metaverse and experience a deep sense of self and
                            personal
                            branding. </p>
                        <p> Etern Avatarz makes it easier to express oneself in the Web3 space. It’s far more
                            than just a
                            virtual representation. It is a reflection of Players’ personalities and authentic
                            identities. The
                            strong connection between the mind and Avatar will create a web3 consciousness that
                            all in
                            their network could feel. </p>
                        <p> Once you have created your perfect Avatar, they become more than a part of your
                            Etern
                            identity: they become an embodiment of your personality and feel deeply connected
                            with each
                            other like never before.</p>
                        <p> This connection will build up your brand and strengthen your presence in the
                            metaverse. As
                            your Mind-Avatar connection grows ever stronger, your Avatarz will live out a life
                            as an
                            expression of yourself — as your alter ego interacting with the entire social
                            metaverse around
                            you.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="twothree">2.3 Metaverse Passport</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p> Etern Metaverse provides Playerz with an innovative, token-gated experience that
                            puts the
                            power of control in their hands. With the Metaverse Passport functionality, Playerz
                            are able to
                            gain access to various Worldz and explore the endless possibilities that await them.
                        </p>
                        <p>Your Etern web3 identity doubles as a Metaverse Passport within the Etern ecosystem,
                            allowing you to unlock special rewardz and access exclusive VIP communities that you
                            belong
                            to. It is an intuitive and secure way to access different virtual spaces and
                            experience a
                            metaverse that is truly limitless. The power of new token gating standards and
                            access control
                            within the Etern Metaverse grants Playerz completely interoperable web3 space that
                            can be
                            explored with ease.</p>
                        <p>Your passport unlocks various access levels depending on what kind of tokenz you
                            possess,
                            giving top contributors access to the most exclusive Worldz. As the metaverse grows,
                            more
                            opportunities to participate in innovative projects will be available to those
                            willing to explore.</p>



                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="MMORPM">3. MMORPM, the Role Playing Metaverse</h2>
                        <h3 id="threeone">3.1 Identity-Centric MMORPM</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Have you ever dreamed of living in a universe of your own creations? An alternate
                            reality where
                            you can connect with exciting people, explore, battle monsters, and become the hero
                            of your
                            own story?</p>
                        <p>MMORPM stands for Massively Multiplayer Online Role Playing Metaverse; a unique
                            concept
                            developed by Etern. This cutting-edge social-gaming meta experience expands on what
                            you
                            already know and love about MMORPGs and takes it to the next level, introducing web3
                            characteristics and providing a more immersive, social environment to explore</p>
                        <p>In the Etern Metaverse, there are endless possibilities for adventures. Challenge
                            yourself to
                            complete daring missions and battles independently or as part of a guild. You’ll be
                            able to
                            create your own story as you explore the 3D Worldz. Along the way, you can make new
                            friends
                            and take on foes in an ever-changing virtual environment.</p>
                        <p>What makes Etern’s MMORPM even more special is its emphasis on personalized
                            storytelling.
                            Playerz can create their own stories and adventures within the metaverse, ensuring
                            that their
                            experiences are always fresh and engaging. Whether you’re facing off against
                            monsters,
                            solving puzzles, completing quests or just connecting with others, MMORPM is the
                            foundation
                            on which you’ll create your own story within the Etern Metaverse.
                        </p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="threetwo">3.2 Gamified Social Metaverse (GSM)</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p> The Etern Metaverse is a pioneering GSM ecosystem where social networking and gaming
                            are intertwined to provide an unparalleled networking experience.</p>
                        <p>It is a virtual universe full of adventure, discovery, and social interaction.
                            Playerz can use the
                            unique social gamification system to collect Quantumz and earn Rewardz, while
                            real-time
                            Leaderboardz let Playerz track their progress and gain clout within the metaverse.
                        </p>
                        <p>For those who'd rather explore than compete, there are always new exciting things to
                            discover
                            and ways to immerse in this alternate reality. Etern is about bringing people
                            together. Whether
                            you're joining forces with friends or accompanying strangers, Etern's living,
                            breathing
                            ecosystem provides a home for all kinds of creators, gamers, and web3 Playerz.</p>
                        <p>It doesn’t end there. Etern Metaverse also features various co-creation tools and
                            multiplayer
                            spaces like virtual Town-halls. These Player-generated environments are like an
                            immersive
                            version of Twitter Spaces within the metaverse, where Playerz can collaborate on
                            projects,
                            create content, and share their work with others—all within the metaverse.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="threethree">3.3 The Teleport Network</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>The inter-dimensional transportation system of the Etern Metaverse is called “the
                            Teleport
                            Network” – an innovative way to travel between the Virtual Worldz. Think of Etern’s
                            Teleportation Network like a supersonic digital subway system – allowing you to
                            travel between
                            virtual destinations instantly.</p>
                        <p>Teleport Stations are located in strategic destinations and each station hosts one or
                            more Teleportals connecting the Teleport Channels. The Teleport Channels are the
                            next generation of
                            Web3 hyperlinks, offering a secure, seamless and lightning-fast way to get around
                            the
                            Metaverse.</p>
                        <p>You can leave location A and instantly arrive at location B. All you need to do is
                            choose your
                            destination or walk through Tele-portals, and you’ll be on your way to the
                            destination in no
                            time. You can even bring up maps of Worldz to help you find your way around and
                            explore the
                            various locations on offer.</p>
                        <p>Playerz can use public Teleport Stations at any given point. Landlordz, on the other
                            hand, are
                            able to own, sell or rent private Teleport Stations located in their Landz and
                            Worldz. We believe
                            that this innovative approach will take virtual tourism to a whole new level,
                            transforming web3
                            transportation into an impactful Travel Economy inside the Etern Metaverse.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="Web3powered">4. Web3-powered Social Gameplay</h2>
                        <h3 id="fourone">4.1 Player-Owned Economy</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>The Etern Metaverse is built around its Playerz. The Playerz can assume different
                            roles and
                            evolve along with the metaverse. We are a gamified social platform which enables the
                            concept
                            of “From the Playerz, For the Playerz” ultimately resulting in a Player-Owned
                            Economy. Our
                            decentralized ecosystem, the in-game assetz and tokenz reflect and encourage this
                            economy.
                        </p>
                        <p>Inside the Etern ecosystem, the Playerz have the option to own collectables such as
                            Artefactz,
                            Gadgetz, Avatarz, and Landz to the extent of Worldz. Playerz will be able to
                            purchase, sell,
                            and trade inside the open Etern Metaverse economy as the ecosystem evolves.</p>
                        <p>A system of skill-based ownership is essential to the ecosystem. Fundamentally as a
                            skillbased ecosystem through their performance in the economy, Playerz can acquire
                            ownership
                            of properties. This develops a healthy environment where Playerz are motivated to
                            advance
                            their abilities and engage with the metaverse.
                        </p>
                        <p>We allow the metaverse's activities to determine how the Etern Metaverse economy
                            develops.
                            Much as GDP is a crucial indicator of economic activity in a real country, Gross
                            Etern
                            Production (GEP) is a benchmark for our economy. The interactions between Playerz
                            and the
                            manner in which trading takes place within the metaverse will define how this
                            economy is
                            developed. We are devoted to making our economy fluid, adjusting to the demands of
                            our
                            participants and their evolving interests.</p>
                        <p>Our Player-owned economy consists of more than just a P2P marketplace for in-game
                            goods
                            and services. We are on a journey towards decentralization. Playerz have the chance
                            to control
                            their metaverse experience with this. The Playerz become more sociable and
                            cooperative as
                            they collaborate to develop a prosperous economy. The distinctiveness of Etern
                            Metaverse
                            compared to other platform experiences comes from this feeling of growth, ownership,
                            synergy
                            and co-creation.</p>
                        <p>The Player-Owned Economy is a central pillar of the Etern Metaverse’s future. It is
                            an
                            ownership system that rewards Playerz for developing their abilities and
                            contributing to the
                            metaverse. We believe this is the future foundation of a truly open, free-market
                            Web3
                            economy.
                        </p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="fourtwo">4.2 Leaderboardz and Quantumz</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Quantumz is the in-game token of the Etern Metaverse ecosystem. It is a metric that
                            is based
                            on a number of variables, including the Player’s accomplishments, activities, and
                            participation
                            in the metaverse.
                        </p>
                        <p>Leaderboardz are used to showcase this metric and might be public, game-specific, or
                            global.
                            In the metaverse, these Leaderboardz foster healthy rivalry and immersive
                            gamification by
                            enabling Playerz to monitor their own success and contrast it with that of other
                            Players. This
                            is what powers Etern to be a “gamified social networking metaverse.”</p>
                        <p>Leaderboardz and Quantumz are intended to give Playerz a measure of their net worth
                            in our
                            metaverse. Playerz advance on the Leaderboardz based on how much they contribute and
                            how much Quantumz they acquire. This establishes a beneficial feedback loop that
                            benefits
                            the ecosystem as well as the Playerz as they are encouraged/rewarded to participate
                            actively
                            within the ecosystem.</p>
                        <p>Leaderboardz and Quantumz will have an important impact on our entire Etern social
                            ecosystem, not just the gaming experience. We anticipate seeing increasingly
                            intricate
                            incentive structures and a stronger focus on metrics and Leaderboardz as the
                            metaverse
                            develops and becomes more sophisticated.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="fourthree">4.3 Leveling up (to infinity)</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Leveling up is a crucial procedure that reflects each Player's privilege levels,
                            status and
                            influence in the ecosystem. In the Etern Metaverse, leveling is an infinite process.
                            This implies
                            that a Player's ability to develop or change within the ecosystem has no upper
                            limit.</p>
                        <p>The Etern Metaverse's leveling system allows equal opportunities for all Playerz to
                            level up
                            using their skills and gain new privileges and surprises. It also illustrates their
                            familiarity and
                            seniority with the Etern Metaverse. Everyone begins at the same level, and depending
                            on their
                            abilities and ecosystem participation, they can level up and grow in influence
                            inside the
                            metaverse.</p>
                        <p>In the Etern Metaverse, leveling up is not just about excitement but also a Player's
                            standing
                            reputation within the ecosystem. A Player's level of influence and reputation within
                            the
                            community increases in direct proportion to how often they participate in and
                            contribute to the
                            metaverse. This fosters constructive competition and motivates Playerz to
                            participate in the
                            ecosystem.</p>
                        <p>The Etern Metaverse's leveling system does not operate in a one-way fashion. In
                            accordance
                            with their participation and activities within the ecosystem, Playerz might also
                            level down. By
                            doing this, it is made sure that the infinite leveling process continues to be fair
                            and gives equal
                            opportunity for all Playerz to evolve within the metaverse.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="EternMetaaLIVEEcosystem">5. EternMeta, a LIVE Ecosystem</h2>
                        <h3 id="fiveone">5.1 Planet Etern</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>The Planet Etern is a multiverse with diverse ecosystems. At its foundation, it is a
                            collection of
                            dynamic Worldz that provide room for incomparable multiplayer activities from gaming
                            to
                            networking. Planet Etern is made up of an endless number of these Worldz, which
                            together
                            form ecosystems with their own economy and social networks.
                        </p>
                        <p>This planet is an online setting that is continuously expanding and evolving over
                            time along
                            with the Habitatz of the Playerz. The variety that Planet Etern offers is among its
                            most important
                            features. We want to build a diverse cosmos where everyone can experience different
                            subcultures. This sense of unity fostered by our communities will enable everyone to
                            come
                            together and express their sense of belonging in the Worldz we construct.</p>
                        <p>Planet Etern will have the unique concept of inclusive-exclusivity at its core. It
                            will provide a
                            welcoming space for all NFTs and provide an equal opportunity for representation.
                            Acknowledging that requirements and preferences may vary between various groups,
                            Planet
                            Etern will cater to the exclusive subcultures while maintaining inclusivity within
                            the community
                            in general. Think of exclusive members-only gated neighborhoods within a larger
                            inclusive
                            ecosystem where everyone is welcome. This enables Etern to maintain a low entry
                            barrier for
                            Playerz to effortlessly start their journey and to progressively evolve into more
                            exclusive,
                            culture-rich communities.</p>
                        <p>A true distinctive feature of Planet Etern is its teleportation map. This allows
                            Playerz to
                            smoothly transition between the Worldz. With its massive, interoperable
                            teleportation map they
                            could immerse in the true Web3 experience of a multiverse.</p>
                        <p>The teleportation feature allows Playerz to engage with people from all over the
                            world in realtime in a matter of seconds; encounter a boundless number of
                            opportunities to connect; and
                            experience true Web3 transportation.</p>
                        <p>We are eager to take you on our voyage into Planet Etern at a time when the metaverse
                            is still
                            at its embryonic stage. From discovering new Worldz to communicating with Playerz
                            worldwide, the bounds are limitless and filled with adventure.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="fivetwo">5.2 Habitatz and Worldz</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>In the Etern Metaverse, your journey begins at Habitatz. A Habitat serves as your
                            base of
                            operations and personal space. Simply, it's your home at Etern! The Habitatz can be
                            customized, allowing for the expression of individualism on the preference of the
                            Playerz.
                        </p>
                        <p>Playerz can start their quests from their own Habitatz using teleportation to explore
                            the Etern
                            Metaverse. The Habitatz are the personal escape for Playerz with complete control,
                            comfort
                            and ownership. After all, there’s no place like home!
                        </p>
                        <p>Worldz on the other hand is a collaborative space. Worldz are created after Landlordz
                            build
                            environments on the Landz they own. These Worldz host multiplayer, gamified social
                            networks
                            to provide Playerz with an immersive web3 experience. Playerz can choose to make
                            their
                            Worldz exclusive (gated) or inclusive (public). Depending on the choice, the
                            evolution of
                            Worldz could be decided by the communities that inhabit them.</p>
                        <p>Let's imagine a city with both public and private sites. In the same manner, the
                            metaverse will
                            have Public Worldz and Private Worldz. Everyone is welcome to enter and use the
                            spaces in
                            the public Worldz, while in private Worldz the owner or the community of owners will
                            hold the
                            power of access.</p>
                        <p>As crucial components of the Etern Metaverse, Habitatz and Worldz make the primary
                            building
                            blocks of the Etern experience. Your adventure begins with Habitatz and expands into
                            Worldz.
                            Most importantly the strength of the community will be “Inclusive-exclusivity”
                            keeping up with
                            the core values of Etern.
                        </p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="fivethree">5.3 - United NFTs (UN)</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Etern thrives on diversity and fairness. Within our ecosystem, every voice will
                            matter, and all
                            endeavors will count. We established the United NFTs (UN) as a frontier for all NFT
                            projects.
                            The vision is to provide all NFT communities with a common and equal metaverse
                            foundation
                            to grow in the Web3 space.</p>
                        <p>While believing in giving everyone an equal chance to be represented, we recognize
                            the
                            distinctiveness of each NFT initiative and work to offer everyone a unique voice. In
                            other words,
                            we support diversity while maintaining the exclusivity of NFT subcultures, depending
                            on NFT
                            holders’ preferences.</p>
                        <p>Regardless of their scope or level of popularity, it is our intention to invite and
                            accommodate
                            all NFT projects. Every project, in our opinion, merits the opportunity to be seen
                            and voiced in
                            the Etern Metaverse. Every NFT Avatar that comes to life represents and contributes
                            to a
                            unique aspect of our ecosystem.</p>
                        <p>One of our guiding principles is that we don't treat anyone differently. Etern stands
                            on the
                            grounds to create an environment where everyone can participate and flourish with no
                            prejudice. Our priority is to establish a warm atmosphere that promotes innovation
                            and
                            teamwork.</p>
                        <p>Our mission is centered on inclusivity. We want to build a web3 space where diversity
                            is valued
                            and respected. No matter the skill level or background, we want every member to feel
                            at ease
                            expressing themselves and displaying their creations in the metaverse. As an
                            initiative
                            designed to give all NFTs a level playing field, we look forward to evolving
                            together to serve
                            all our communities better!
                        </p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="TheMetaverseLaunchpad">6. The Metaverse Launchpad</h2>
                        <h3 id="sixone">6.1 Development of Landz</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Etern Landlordz are the key holders who maintain the decentralized nature of the
                            Etern
                            Metaverse ecosystem. Etern Landz, the primary blocks of real estate in the Etern
                            Metaverse,
                            are exclusively owned by Landlordz. They share the ownership of access and hosting
                            of Assetz
                            and Worldz. </p>
                        <p>This status includes a number of benefits, such as granting exclusive access to Etern
                            Playerz
                            and the power to create Worldz on their Landz. Landlordz have the ability to develop
                            their own
                            personal metaverse on their properties, and they can do it themselves or with
                            developer
                            assistance programs like the Metaverse Launchpad by Etern.</p>
                        <p>Etern Landz support hosting on-chain collectables, and 3D Assetz. They are
                            multiplayerpowered and customizable. The characteristics and terrains of Landz will
                            change over time,
                            reflecting the evolving nature of the Etern Metaverse. Based on things like user
                            growth and
                            interaction, Landz and its attributes will be modified.</p>
                        <p>The Launchpad offers primarily three ways to build on Landz: utilizing Development
                            Creditz,
                            building/importing your own Assetz, and later using the Space-Time builder (Etern’s
                            upcoming
                            DIY world builder solution). </p>
                        <p>At Etern we believe that your virtual real estate should never be barren land. Our
                            mission is to
                            support our Landlordz and Playerz to create resourceful as well as useful
                            environments. The
                            Metaverse Launchpad program aims to become a virtual accelerator for your metaverse
                            ideas
                            to come to life.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="sixtwo">6.2. Development Creditz</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Etern’s Development Creditz is a program that is intended to incentivize the
                            construction of
                            metaverse infrastructure on Etern Landz. The initiative serves as an incentive for
                            Landlordz
                            and other qualified Playerz who are interested in creating new Etern Metaverse
                            Worldz. With
                            the initiative, they’ll have access to discounted metaverse Dev Creditz from
                            recognized Etern
                            Metaverse partners including DejaVu Quest, the original developer of Etern’s
                            technology
                            infrastructure. The goal of this is to guarantee the long-term growth of Worldz
                            within the
                            metaverse.</p>
                        <p>Landlordz who are eligible for the Development Creditz will receive discounts which
                            are
                            decided on a quarterly basis by the partners of the Etern Metaverse. The Dev Creditz
                            can be
                            claimed by Landlordz, eligible organizations and Playerz when they utilize services
                            by Etern
                            Meta Dev partners to create unique Avatarz, Environmentz, and 3D Assetz populating
                            the 4D
                            Worldz. The program is designed as a support system for the builders of the Etern
                            Ecosystem.</p>
                        <p>The Etern Metaverse intends to occasionally give Dev Creditz in the form of Rewardz
                            and
                            Grantz to qualified applicants selected through a rigorous application process. At
                            the time of
                            writing, the requirements of the ecosystem will determine the specifics of each Dev
                            Creditz program. The program's goal remains constant nevertheless, as it is to offer
                            a resourceful
                            support network so that a robust ecosystem of Worldz will be developed within the
                            Etern
                            Metaverse.</p>
                        <p>It is important to note that the Etern Metaverse and DejaVu Quest, Inc. do not
                            guarantee the
                            availability of development services nor the specific discounts on the services
                            offered as it all
                            depends on demand-supply and market conditions.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="sixthree">6.3 Space-Time Builder</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Imagine being able to design and build your own unique Avatarz, Environmentz, 3D
                            Assetz
                            and Worldz in the Etern Metaverse with no coding skills. Your go-to tool for
                            bringing these
                            experiments to life will be Etern’s upcoming “Space-Time Builder.”
                        </p>
                        <p>You will have access to this “Do-It-Yourself” no-code solution as Landlordz or
                            Creatorz,
                            allowing you to build 3D/4D objects and deploy them directly within the Etern
                            Metaverse. This
                            experimental feature of the Etern Metaverse is meant to completely change the way
                            you
                            construct your metaverse space. (Note: At the time of writing, the Space-Time
                            Builder is at
                            pre-alpha prototype stage). </p>
                        <p>Your design is meant to receive a customized touch from the Space-Time builder
                            without
                            sacrificing quality. Once the beta version of the Space-Time Builder is released
                            within Etern
                            you’ll have access to libraries of reference and have the ability to build on Landz.
                        </p>
                        <p>We believe that the Space-Time Builder will play an important role in the Etern
                            Metaverse's
                            future, as it offers an opportunity for everyone to design their own unique
                            environment without
                            any technical experience.</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="Whatwebelievein">7. What we believe in</h2>
                        <h3 id="sevenone">7.1 Core Values</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>At the Etern Metaverse, we obsessively believe in being Player-centric. We value our
                            Players'
                            needs and wants above everything else.</p>
                        <p>Etern believes in cultivating genuine human connections. We consider fostering
                            relationships
                            within our community to be the ultimate priority over hype. Developing a welcoming,
                            secure,
                            and courteous atmosphere that encourages Player interaction is one of our key
                            guiding
                            principles because of this.</p>
                        <p>Our community is a space where we encourage cooperation and teamwork among Playerz.
                            By connecting and bonding over shared experiences, we envision relationships that go
                            beyond
                            a web3 social network. This is what drives us to support events that foster
                            community building,
                            teamwork, and interpersonal communication.
                        </p>
                        <p>Following community placement, fairness is something we value parallelly. In our
                            opinion,
                            everyone should have an equal opportunity for representation. To achieve this, we
                            work closely
                            with our community to establish just and reasonable game rules that apply to
                            everyone equally.
                            A level playing field where Playerz will thrive based on their talents and
                            abilities, rather than
                            their status or connections.</p>
                        <p>Our Playerz are here to have a good time. As their host, our duty is to make sure
                            that the
                            Worldz are designed like theme parks, built to challenge as well as to amuse them.
                            We support
                            activities that promote healthy competition in the community and generate a sense of
                            joy and
                            contentment that ultimately bring subcultures together. This is what we call a
                            gamified social
                            metaverse. </p>
                        <p>In this context, we are highly focused on long-term strategy over short-term gains.
                            Establishing
                            a truly sustainable value system takes time. We are committed to investing in the
                            sustained
                            growth of our ecosystem because we recognize that developing a successful community
                            requires effort and endurance. With our Playerz, we always strive to build
                            long-lasting
                            relationships that are authentic, nurturing and aligned with our core values.</p>
                        <p>Community, justice, enjoyment, and a long-term perspective are at the core of Etern's
                            ideals.
                            We believe that giving these values top priority will help us and our community
                            create a
                            welcoming, equitable, and enjoyable metaverse. As Playerz, you are at the center of
                            our
                            universe and we are proud to share this special journey with you!</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="seventwo">7.2 Guiding Principles</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>Our guiding principles are based on long-term thinking, player-centered design and
                            firmly believing in the power of web3 and metaverse as inevitable technologies. </p>
                        <h2 className="smail-titel">1. Player first. Always.</h2>
                        <p>Obsessive focus on the Player centric web3 experience. Playerz are our primary
                            customers and their identity is our top priority.</p>
                        <h2 className="smail-titel">2. Deep relationships over hype.</h2>
                        <p>Nurture a human-centered, deep and organic metaverse/web3 community
                            engagement. No hype or undue FOMO.</p>
                        <h2 className="smail-titel">3. Generate measurable utility. </h2>
                        <p>Aim for long-term consistent growth by delivering measurable metaverse utility.</p>
                        <h2 className="smail-titel">4. Culture of technical excellence. (Builders’ mindset). </h2>
                        <p>We don’t care how markets move. We just build, set high benchmarks for ourselves
                            and strive for web3 technical excellence.
                        </p>
                        <h2 className="smail-titel">5. Agile metaverse development.</h2>
                        <p>We firmly believe in iterative and incremental development – launching early and
                            getting feedback fast.</p>
                        <h2 className="smail-titel">6. Innovation oriented. Efficiency driven.</h2>
                        <p>We rely on new and innovative solutions to address challenges. An innovative solution
                            is often need-based, less resource intensive and requires undivided obsessive focus.
                        </p>
                        <h2 className="smail-titel">7. Collaboration is the key strategy.</h2>
                        <p>We encourage collaboration over competition to create value and growth for the
                            community. </p>
                        <h2 className="smail-titel">8. Long-term focus trumps.</h2>
                        <p>We focus on building long-term value and utility over short-term vanity metrics.</p>
                    </div>
                </div>

                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="TheVision">8. The Vision</h2>
                        <h3 id="eightone">8.1 The birth of a Virtual Human Race</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>The Metaverse will be inevitable as humanity enters a new era of technology. With
                            that,
                            authentic virtual identity and unique web3 profiles that act as an accurate
                            depiction of the
                            person who owns it will fuel this virtual world</p>
                        <p>We introduce you to Etern Metaverse, the digital humanity of the future, on the basis
                            of the
                            above concept. At Etern, digital identities serve as the primary means of entry into
                            the
                            Metaverse. In a future where humans will be virtual, Etern will offer an eternal
                            number of
                            opportunities and choices.
                        </p>
                        <p>We are here to create unique virtual identities of ourselves in order to contribute
                            to the evolution
                            of digital identities. The digital human race of Etern that inhabits this new
                            civilization, is referred
                            to as "Homo Eternalz,” who have no physical borders and live in the virtual realm.
                            We consider
                            this as the critical following stage in human evolution.</p>
                        <p>As a member of the Homo Eternalz race, you will be exposed to the infinite
                            possibilities of the
                            Etern Metaverse and free from the constraints of physical reality. You will also be
                            connected
                            to and empowered by advanced web3 technology.
                        </p>
                        <p>Imagine being able to interact, explore, share, play, own, create, or connect with
                            other Eternalz
                            in a dream world that is wholly immersive. That is what Etern metaverse exactly
                            envisions it to
                            be!
                        </p>
                        <p>Unparalleled levels of freedom will be available in Etern Metaverse. No longer will
                            geography
                            or customs be a barrier to our freedom. Without worrying about criticism, Eternalz
                            are free to
                            be who they are, to make mistakes and learn from them, to be inventive, and to share
                            their
                            thoughts.
                        </p>
                        <p>We are here to reimagine how people communicate, collaborate, and engage with one
                            another. We look forward to welcoming you to the Etern Metaverse, home of Eternalz
                            and the
                            next generation of a new digital human race.
                        </p>
                    </div>
                </div>

                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="eighttwo">8.2 Establishing Web3 Consciousness</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>We are mindful of the significance of building a web3 consciousness as we continue to
                            develop
                            the Etern Metaverse. This describes a society that has embraced the metaverse's
                            culture and
                            is cognizant of its digital surroundings. The goal is to thrive and build a deep,
                            rich culture within
                            the digital world, not just to survive within it.</p>
                        <p>The web3 consciousness is about inclusivity, and we're dedicated to fostering a
                            setting where
                            everyone feels like they belong. Instead of focusing on explicit exclusion, the goal
                            should be
                            to build a community that is exclusively inclusive. It's about appreciating the
                            uniqueness and
                            realizing that each has something special to contribute.
                            We are concentrating on building a linked, immersive experience as we expand the
                            Etern
                            Metaverse. In addition to being aesthetically appealing, the goal is to create a
                            universe that is
                            intellectually stimulating.</p>
                        <p>Furthermore, Etern believes in cultivating innovative and collaborative culture as we
                            deeply
                            want to contribute to establishing web3 consciousness. To build engaging new
                            experiences in
                            the metaverse, we aim to inspire our Playerz to collaborate.</p>
                        <p>The idea that the metaverse is a new way of thinking and living, rather than just
                            virtual reality,
                            lies at the core of the web3 consciousness. It provides a platform for interaction
                            with individuals
                            from around the globe and experiences that are not attainable in the physical world.
                            A perfect
                            example would be the Oasis in Spielberg’s Ready Player One.</p>
                        <p>Similar to the Oasis we believe that how we live, work, and interact with one another
                            could be
                            reimagined by the Etern Metaverse. Aligned with this vision, we are eager to create
                            a healthy
                            web3-conscious community, pioneering a unique web3 culture.</p>
                    </div>
                </div>

                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h3 id="eightthree">8.3 Envisioning Digital Immortality</h3>
                    </div>
                    <div className="white-paper-card-body">
                        <p>A vivid fantasy of a distant future in which virtual humans are made everlasting by
                            technology
                            is what gave rise to the idea of the Etern metaverse. At Etern, we think that human
                            consciousness will develop to live outside of physical constraints and that the
                            future is virtual.
                        </p>
                        <p>We hypothesize that when people enter a true immersive environment and develop web3
                            consciousness, they create a virtual identity and a persona that is unconstrained by
                            physical
                            limitations and has the potential to be truly eternal.</p>
                        <p>With the development of ground-breaking AI and VR technology, we will have the
                            opportunity
                            to create a self-sustaining version of ourselves. Etern believes by integrating AI,
                            VR and
                            blockchain technology there is a possibility to build an immortal version of you.
                        </p>
                        <p>Although the concept of digital immortality may sound far-fetched, Etern believes it
                            is
                            eventually feasible based on current and upcoming technological breakthroughs. Our
                            objective
                            is to build a platform that will enable users to protect their online identities and
                            leave a lasting
                            legacy. </p>
                        <p>The potential is limitless, and we picture a world in which people may preserve the
                            essence of
                            their loved ones virtually and keep their memories alive for future generations.
                            This is Etern
                            Metaverse's far horizon, a vision so beautiful and inspiring that we are compelled
                            to at least
                            take a small step towards that vision each day!</p>
                        <p>"It's not the mountain we conquer, but ourselves” <br/>
                            Sir Edmund Hillary</p>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="Terminology">Terminology</h2>
                    </div>
                    <div className="white-paper-card-body">
                        <ul>
                            <li>Planet Etern: The Planet Etern is a multiverse with diverse ecosystems. It is a
                                collection
                                of dynamic Worldz that provide room for incomparable multiplayer activities from
                                gaming to networking.</li>
                            <li>Landz: The Etern Metaverse's primary building blocks of real estate.</li>
                            <li> Playerz: Playerz, are those who interact within the Etern Metaverse. In other words, 
                                the participants of the Etern Metaverse.
                                </li>
                            <li> Habitatz: The personal spaces of Playerz within Etern that can be customized
                                based
                                on their preference. Playerz enjoy complete control, comfort and ownership of
                                Habitatz. </li>
                            <li>Worldz: Worldz are created when Landlordz build environments on the Landz they
                                own.
                                Worldz host multiplayer, gamified social networks to provide Playerz with an
                                immersive
                                web3 experience. </li>
                            <li>Avatarz: 4D virtual characters that represent each Playerz identity within the
                                Etern
                                Metaverse. Playerz can transform their 2D PFP NFT into Avatarz and bring them to
                                life
                                within the Etern Metaverse.</li>
                            <li>Rewardz: Digital tokens/assets that Playerz receive as recognition for their
                                accomplishments/contributions to Etern.
                            </li>
                            <li>Creditz: Digital discounts/ tokens Playerz receive to build/evolve within the
                                Etern
                                Metaverse. Tokenz can be used to buy Landz, Habitatz, and other in-game virtual
                                goods.</li>
                            <li>
                                Contestz: Contestz are competitions Etern Playerz participate in.These might
                                consist
                                of in-game challenges or competitions that display the skills and prowess of
                                Playerz.
                            </li>
                            <li>Eventz: Includes public or private social gatherings that take place within the
                                Etern
                                Metaverse.
                            </li>
                            <li>Landlordz: Exclusive owners of the Landz within Etern Metaverse. Landlordz can
                                grant
                                exclusive access to Playerz and have the power to create Worldz on their Landz.
                            </li>
                            <li>Quantumz: In-game tokens of the Etern Metaverse. It is a metric used to track
                                Playerz's
                                activity, accomplishments and participation within Etern. Quantumz are used to
                                rank
                                Playerz on the Leaderboardz.</li>
                            <li>Leaderboardz: A scoreboard that showcases the Quantumz count of each Etern
                                Player.</li>
                        </ul>
                    </div>
                </div>
                <div className="white-paper-card">
                    <div className="white-paper-card-header">
                        <h2 id="Disclaimer">Disclaimer</h2>
                    </div>
                    <div className="white-paper-card-body">
                        <p>The information in this White Paper should not be interpreted as a commitment,
                            promise, or
                            guarantee by the Etern Metaverse or any other person or organization mentioned in
                            this
                            document, regarding the features and availability of the metaverse platform,
                            products,
                            services, collectibles, tokens or any other offerings including their future
                            performance or value.</p>
                        <p>The document is not a solicitation or offer to buy or sell securities. It is not an
                            offer to sell or
                            subscribe for securities, nor does it constitute or form a part of one, and it
                            should not be
                            interpreted as such. Neither should it, in whole or in part, serve as the foundation
                            for or be
                            relied upon in connection with any contract or commitment of any kind.
                        </p>
                        <p>The Etern Metaverse and DejaVu Quest, Inc. expressly disclaim all liability for any
                            loss or
                            damage, direct or indirect, of any type that may arise from the use of any
                            information in the
                            Whitepaper directly or indirectly, including any error, omission, or inaccuracy in
                            such material,
                            or from any action taken in reliance thereon.</p>
                        <p>This Whitepaper is strictly informational. It does not offer purchase suggestions or
                            investment
                            advice. Do not solely rely on this information while purchasing any product,
                            service,
                            subscription, or token, related or unrelated to Etern Metaverse and DejaVu Quest,
                            Inc. Any
                            investment carries a risk of loss, including but not limited to insufficient
                            liquidity, and possible
                            principal loss. Before making any investment decisions, investors should undertake
                            their own
                            independent due diligence on the subjects included in this publication with the help
                            of
                            appropriate experts to form an independent opinion.
                        </p>
                        <p>Certain statements, projections and forecasts featured in this whitepaper are
                            forward-looking
                            statements and no guarantees are offered regarding them. Certain known and unknown
                            contingencies and risks, majority of which are out of our control may in eventuality
                            cause the
                            estimated results to differ factually and substantially from the featured
                            projections or results
                            extrapolated or expressed in such forward-looking statements herewith.</p>
                        <p>All of the information in this Whitepaper was compiled using sources that we consider
                            to be
                            accurate. However, such material is provided "as is," without express or implied
                            warranties. All
                            statistics and other information are based on chosen public market data, reflect
                            current
                            conditions, and represent our opinion as of this date. As a result, they are all
                            subject to change
                            without prior notice.</p>
                    </div>
                </div>


            </div>
        </div>
       
    </div>
</div>
</div>
  )
}

export default WhitepaperCard