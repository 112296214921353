import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Platform from '../components/Platform/Platform'

const PlatformsPage = () => {
  return (
    <>
    <Navbar/>
    <Platform/>
    </>
  )
}

export default PlatformsPage