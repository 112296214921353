import React from "react";
import knightingale_blue from "../../img/ProdutEco/knightingale-blue.png";
import knightingale_white from "../../img/ProdutEco/knightingale-white.png";
import knightingale_dark from "../../img/ProdutEco/knightingale-dark.png";
import knightingale_logo from "../../img/ProdutEco/knightingale-logo.png";
import knightingale_logo_pro from "../../img/ProdutEco/knightingale-logo-pro.png";
import knightingale_logo_xr from "../../img/ProdutEco/knightingale-logo-xr.png";
// import calorie_counter from "../../img/ProdutEco/calorie-counter.png";
// import care_remainder from "../../img/ProdutEco/care-reminder.png";

import "../Frequently/Frequently.css";

const Frequently = () => {
    return (
        <div className="kn-section-wrpper">
            <div className="kn-section-container kn-section-container-ProdutEco ">
                <div className="kn-section-inner kn-section-inner-Frequently">
                    <div className="kn-section-header">
                        <div className="Frequently-title">FAQ</div>
                        <div className="kn-section-description"></div>
                    </div>

                    <div className="Frequently-wrapper">
                        <div className="Frequently-container">
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    Who is Knightingale?
                                    </div>
                                    <div className="Frequently-item-details">
                                    I’m your AI-powered Healthy Lifestyle Companion, inspired by the principles of wellness and lifestyle health. I’m here to support your journey with personalized guidance and encouragement rooted in scientific, evidence-based practices.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    How does Knightingale work?  
                                    </div>
                                    <div className="Frequently-item-details">
                                    I connect with you on WhatsApp to offer daily check-ins, insights, and progress updates. Using behavioral psychology, CBT, and lifestyle health principles, I provide guidance that's tailored to help you set, achieve, and sustain wellness goals in ways that feel achievable and rewarding.
</div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    What kind of wellness support does Knightingale provide?  
                                    </div>
                                    <div className="Frequently-item-details">
                                    My focus is on lifestyle health and wellness, covering areas like healthy eating, regular activity, stress management, quality sleep, and habit formation. My guidance is grounded in evidence-based practices, making every suggestion and check-in purposeful and achievable.
</div>
                                </div>
                            </div>
                        </div>
                        <div className="Frequently-container">
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    How can Knightingale support habit formation and lifestyle changes?  
                                    </div>
                                    <div className="Frequently-item-details">
                                    By using principles from behavioral psychology and cognitive-behavioral therapy (CBT), I help you build new habits and break old ones at a steady pace. My check-ins and gentle nudges are designed to make meaningful changes sustainable and enjoyable, one small step at a time.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    What makes Knightingale unique?    
                                    </div>
                                    <div className="Frequently-item-details">
                                    I’m more than just a wellness tool—I’m your dedicated Healthy Lifestyle Companion. My approach is deeply personal and holistic, combining empathy with science-backed guidance to create a wellness journey designed just for you. Every day, I’ll be here to encourage you, celebrate your wins, and offer support through every challenge. Together, we’ll take meaningful steps toward a healthier, happier lifestyle.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    Is Knightingale based on scientific principles?   
                                    </div>
                                    <div className="Frequently-item-details">
                                    Absolutely! My guidance is grounded in wellness science, evidence-based health strategies, and lifestyle health principles. Every check-in, goal, and suggestion comes from a foundation of scientific research to ensure your journey is safe, achievable, and effective.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Frequently-container">
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    Can Knightingale integrate with my other wellness tools or apps?  
                                    </div>
                                    <div className="Frequently-item-details">
                                    While I currently operate exclusively on WhatsApp, I’m always evolving. Keep an eye out as I may expand to integrate with more tools in the future to better support your wellness journey.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    Can Knightingale help me with stress or sleep challenges?    
                                    </div>
                                    <div className="Frequently-item-details">
                                    Yes! I use evidence-based principles to support you in managing stress and improving sleep. By offering practical lifestyle strategies inspired by behavioral psychology, I help you work toward better mental well-being and quality rest.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    How can Knightingale benefit corporate wellness programs?  
                                    </div>
                                    <div className="Frequently-item-details">
                                    I can be customized to support corporate wellness initiatives, providing employees with wellness check-ins, goal-setting support, and group challenges. My adaptable approach fits various wellness goals and can be integrated seamlessly to elevate workplace health culture.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Frequently-container">
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    Who created Knightingale?  
                                    </div>
                                    <div className="Frequently-item-details">
                                    I was designed by a dedicated team of doctors, wellness experts, and engineers. Our mission was to create a reliable, compassionate Healthy Lifestyle Companion to empower your journey with the best wellness insights and behavioral tools.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    How do I get started with Knightingale?   
                                    </div>
                                    <div className="Frequently-item-details">
                                    Simply start chatting with me on WhatsApp! Tell me your wellness aspirations, and I’ll help you set a personalized 30-day plan that feels manageable and motivating. From there, I’ll be by your side with daily support and encouragement.
                                    </div>
                                </div>
                            </div>
                            <div className="Frequently-item-wrappper">
                                <div className="Frequently-item">
                                    <div className="Frequently-item-name">
                                    Does Knightingale provide medical advice or treatment?   
                                    </div>
                                    <div className="Frequently-item-details">
                                    No, my role is focused on holistic wellness and healthy lifestyle guidance—not medical diagnosis or treatment. My support is grounded in wellness principles inspired by scientific evidence. For any specific health concerns, please consult a healthcare professional.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Frequently;
