import React from "react";
import Personalized from "../../img/humanized/personalized.png";
import healthExpert from "../../img/humanized/health-expert.png";
import multimodal from "../../img/humanized/multimodal.png";
import empathic from "../../img/humanized/empathic.png";

import "../Humanized/Humanized.css";

const Humanized = () => {
  return (
    <div className="humanized-wrpper">
      <div className="humanized-container">
        <div className="humanized-inner">
          <div className="humanized-title">Wellness is a journey, not a destination.</div>
          <div className="humanized-text">
          Together, we’ll make small, meaningful changes grounded in science and guided by empathy—one step at a time, toward a healthier, happier you.
          </div>
          <div className="humanized-wrapper">
            <div className="humanized-detail-container">
              <div className="humanized-detail-inner-wrapper">
                <div className="humanized-detail-inner">
                  <div className="humanized-detail">
                    <div className="humanized-img">
                      <img src={Personalized} alt="" />
                    </div>
                    <div className="humanized-detaile-title">
                    Intelligent<br/> Guidance
                    </div>
                    <div className="humanized-details-text">
                    Evidence based lifestyle wellness, powered by CBT and behavioral psychology.

                    </div>
                  </div>
                </div>
                <div className="humanized-detail-inner">
                  <div className="humanized-detail">
                    <div className="humanized-img">
                      <img src={ healthExpert} alt="" />
                    </div>
                    <div className="humanized-detaile-title">Adaptive and Personalized</div>
                    <div className="humanized-details-text">
                    I adapt to your needs, learning from our progress to ensure your wellbeing.
                    </div>
                  </div>
                </div>
              </div>
              <div className="humanized-detail-inner-wrapper">
                <div className="humanized-detail-inner">
                  <div className="humanized-detail">
                    <div className="humanized-img">
                      <img src={multimodal} alt="" />
                    </div>
                    <div className="humanized-detaile-title">Empathetic Companion</div>
                    <div className="humanized-details-text">
                    In your wellness journey, I'll be with you every step of the way—judgment-free.
                    </div>
                  </div>
                </div>
                <div className="humanized-detail-inner">
                  <div className="humanized-detail">
                    <div className="humanized-img">
                      <img src={empathic} alt="" />
                    </div>
                    <div className="humanized-detaile-title">Confidential & Secure</div>
                    <div className="humanized-details-text">
                    My priority is your privacy and creating a safe, secure space for your journey.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Humanized;
