import React, { useState } from 'react';
import './Trailer.css';
import trailer_2_loop from '../../video/knightingale_trailer_loop.mp4';
import knightingale_trailer_loop_thumbnail from '../../video/knightingale_trailer_loop_thumbnail.jpg';
import play_icon from '../../img/trailer/play_icon.png';

function Trailer() {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="trailer-container">
      {/* <div className="humanized-title">Watch Knightingale Trailer</div> */}
      <div className='trailer-glow'>
        <div className="trailer-wrapper">
          {/* Trailer video */}
          <video
            className="trailer-trailervideo"
            onClick={openModal} // Open modal on click
            style={{ cursor: 'pointer', maxWidth: '1200px', borderRadius: '8px' }}
            playsInline
            autoPlay
            loop
            muted
            poster={knightingale_trailer_loop_thumbnail}
            src={trailer_2_loop}
          />
          {/* Play Button Centered */}
          <button className="trailer-videobtn" onClick={openModal}>
            <img
              src={play_icon}
              alt="Play Button"
            />
          </button>
        </div>

      </div>

      {/* Custom Modal */}
      {showModal && (
        <div className="trailer-custommodal">
          <div className="trailer-modalcontent">
            <button onClick={closeModal} className="trailer-closemodalbtn">×</button>
            <iframe
              src="https://player.vimeo.com/video/1019847738"
              width="100%"
              height="500px"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Vimeo Video"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default Trailer;
