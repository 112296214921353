import React from 'react'
import inzu_kody_profile from "../../img/Leardeship/inzu_kody_profile.png";
import dr_dul_wickramanayake_profile from "../../img/Leardeship/dr_dul_wickramanayake_profile.png";
import deepamala_abeysekera_profile from "../../img/Leardeship/deepamala_abeysekera_profile.png";
import prof_allan_barrel_profile from "../../img/Leardeship/prof_allan_barrel_profile.png";
import dr_shakthi_kumar_profile from "../../img/Leardeship/dr_shakthi_kumar_profile.png";
import ajith_abeysekera_profile from "../../img/Leardeship/ajith_abeysekera_profile.png";
import dr_vidushi_babber_md_profile from "../../img/Leardeship/dr_vidushi_babber_md_profile.png";   
import jamie_michaels_profile from "../../img/Leardeship/jamie_michaels_profile.png";
import payal_shankar_profile from "../../img/Leardeship/payal_shankar_profile.png";
import rudi_sellers_profile from "../../img/Leardeship/rudi_sellers_profile.png";

import linkedin_icon from "../../img/Leardeship/linkedin_icon.jpg";
import "../LeaderShipCard/LeaderShipCard.css";

const LeaderShipCard = () => {
    return (
        <div className='leadership-wrapper'>
            <div className='leadership-container'>
                <div className='leadership-inner'>
                    <div className='leadership-cards-wrapper'>
                        <div className='leadership-cards-container'>
                            <div className='leadership-cards-inner'>
                                <div className='leadership-cards-title-wrapper'>
                                    <div className='leadership-cards-title-container'>
                                        <div className='leadership-cards-title'>
                                            Leadership Team
                                        </div>
                                    </div>
                                </div>
                                <div className='leadership-card-wrapper-2item'>
                                    <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={inzu_kody_profile} alt='inzu_kody_profile' />
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                            Dr. Inzu Kody
                                                        </div>
                                                        <div className='leadership-card-position-inner'>
                                                            Cofounder/CEO
                                                        </div>
                                                    </div>
                                                    <div className='leadership-linkdin'>
                                                        <a href='https://www.linkedin.com/in/inzu-kody/'>
                                                            <img src={linkedin_icon} alt='linkedin_icon' />
                                                            <span>https://www.linkedin.com/in/inzu-kody/</span>
                                                        </a>
                                                    </div>
                                                    <div className='leadership-card-details '>
                                                        <ul className='leadership-card-details-inner'>
                                                            <li className='leadership-card-details-1 leadership-card-details-text'>
                                                                Medical Doctor + Software Engineer
                                                            </li>
                                                            <li className='leadership-card-details-2 leadership-card-details-text'>
                                                                Health AI executive training from Harvard Medical School.
                                                            </li>
                                                            <li className='leadership-card-details-3 leadership-card-details-text'>
                                                                Fellow of Royal Society of Medicine. World prize from Chartered Institute for IT.
                                                            </li>
                                                            <li className='leadership-card-details-4 leadership-card-details-text'>
                                                                16+ years in tech entrepreneurship and product innovation.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='leadership_position'>

                                                        MBBS, FRSM, MBCS, MIEEE, MBA
                                                    </div>
                                                </div>
                                                {/* <div className='leadership-card-button-wrapper'>
                                                    <div className='leadership-card-button-inner'>
                                                        <div className='leadership-card-button-container'>
                                                            <a href=''>
                                                                <img src={linkedin_icon} alt='linkedin_icon' />
                                                                <span>View Profile</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={deepamala_abeysekera_profile} alt='inzu_kody_profile' />
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                            Deepamala Abeysekera
                                                        </div>
                                                        <div className='leadership-card-position-inner'>
                                                            Cofounder/President
                                                        </div>
                                                    </div>
                                                    <div className='leadership-linkdin'>
                                                        <a href='https://www.linkedin.com/in/deepamala-abeysekera-73a66822/'>
                                                            <img src={linkedin_icon} alt='linkedin_icon' />
                                                            <span>https://www.linkedin.com/in/deepamala</span>
                                                        </a>
                                                    </div>
                                                    <div className='leadership-card-details'>
                                                        <ul className='leadership-card-details-inner'>
                                                            <li className='leadership-card-details-1 leadership-card-details-text'>
                                                                Cambridge (UK) and LSE alumni. Lawyer, BBC-trained ex-journalist/TV host.
                                                            </li>
                                                            <li className='leadership-card-details-2 leadership-card-details-text'>
                                                                UNESCO Scholar, human rights awardee, with United Nations experience.
                                                            </li>
                                                            <li className='leadership-card-details-3 leadership-card-details-text'>
                                                                Multiple tech and biz awards.
                                                            </li>
                                                            <li className='leadership-card-details-4 leadership-card-details-text'>
                                                                16+ years in tech entrepreneurship, global partnerships and business development.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='leadership_position'>

                                                        LLM, LLB, BSc Economics
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   

                                </div>
                                <div className='leadership-card-wrapper-2item'>
                                <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={dr_dul_wickramanayake_profile} alt='dr_dul_wickramanayake_profile' />
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                            Dr. Dulmini Wickramanayake
                                                        </div>
                                                        <div className='leadership-card-position-inner'>
                                                            Cofounder/CEO
                                                        </div>
                                                    </div>
                                                    <div className='leadership-linkdin'>
                                                        <a href='https://www.linkedin.com/in/dr-dulmini-wickramanayake-2730ba146/'>
                                                            <img src={linkedin_icon} alt='linkedin_icon' />
                                                            <span>https://www.linkedin.com/in/dr-dulmini-wickramanayake</span>
                                                        </a>
                                                    </div>
                                                    <div className='leadership-card-details'>
                                                        <ul className='leadership-card-details-inner'>
                                                            <li className='leadership-card-details-1 leadership-card-details-text'>
                                                                Medical Doctor + Process Engineer
                                                            </li>
                                                            <li className='leadership-card-details-2 leadership-card-details-text'>
                                                                Professional background in medicine, business and engineering.
                                                            </li>
                                                            <li className='leadership-card-details-3 leadership-card-details-text'>
                                                                Agile methodology and mindfulness practitioner.
                                                            </li>
                                                            <li className='leadership-card-details-4 leadership-card-details-text'>
                                                                16+ years in project management and customer happiness.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='leadership_position'>
                                                        MBBS, MBCS, MBA
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={inzu_kody_profile} alt='inzu_kody_profile' />
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                            Dr. Inzu Kody
                                                        </div>
                                                        <div className='leadership-card-position-inner'>
                                                            Cofounder/CEO
                                                        </div>
                                                    </div>
                                                    <div className='leadership-linkdin'>
                                                        <a href=''>
                                                            <img src={linkedin_icon} alt='linkedin_icon' />
                                                            <span>View Profile</span>
                                                        </a>
                                                    </div>
                                                    <div className='leadership-card-details'>
                                                        <ul className='leadership-card-details-inner'>
                                                            <li className='leadership-card-details-1 leadership-card-details-text'>
                                                                Medical Doctor + Software Engineer
                                                            </li>
                                                            <li className='leadership-card-details-2 leadership-card-details-text'>
                                                                Health AI executive training from Harvard Medical School.
                                                            </li>
                                                            <li className='leadership-card-details-3 leadership-card-details-text'>
                                                                Fellow of Royal Society of Medicine. World prize from Chartered Institute for IT.
                                                            </li>
                                                            <li className='leadership-card-details-4 leadership-card-details-text'>
                                                                16+ years in tech entrepreneurship and product innovation.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='leadership_position'>

                                                        MBBS, FRSM, MBCS, MIEEE, MBA
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='leadership-cards-wrapper'>
                        <div className='leadership-cards-container'>
                            <div className='leadership-cards-inner'>
                                <div className='leadership-cards-title-wrapper'>
                                    <div className='leadership-cards-title-container'>
                                        <div className='leadership-cards-title'>
                                            Strategic Advisory Board
                                        </div>
                                    </div>
                                </div>
                                <div className='leadership-card-wrapper-2item'>
                                    <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={prof_allan_barrel_profile} alt='prof_allan_barrel_profile'  className='leadership-card-img-product'/>
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                        Prof. Alan Barrell
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='leadership-linkdin'>
                                                  
Former Chairman of National Health Service (NHS) Innovation Council, UK
                                                    </div>
                                                    <div className='leadership-card-details'>
                                                        <ul className='leadership-card-details-inner'>
                                                            <li className='leadership-card-details-1 leadership-card-details-text'>
                                                            Former Chairman/CEO of multinationals in healthcare and medical research.
                                                            </li>
                                                            <li className='leadership-card-details-2 leadership-card-details-text'>
                                                            Former Venture Capitalist/Investor in Silicon Fen - Cambridge, UK.
                                                            </li>
                                                            <li className='leadership-card-details-3 leadership-card-details-text'>
                                                            Former Entrepreneur in Residence at University of Cambridge.
                                                            </li>
                                                            <li className='leadership-card-details-4 leadership-card-details-text'>
                                                            Queen’s Award (UK) and Knight First Class (Finland) for outstanding contributions.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={ajith_abeysekera_profile} alt='ajith_abeysekera_profile'  className='leadership-card-img-product'/>
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                        Ajith Abeysekera
                                                        </div>
                                                       
                                                    </div>
                                                    <div className='leadership-linkdin'>
                                                    Founder Chairman of Aspirations Education, with ties to a network of 200 Global Universities.
                                                    </div>
                                                    <div className='leadership-card-details'>
                                                        <ul className='leadership-card-details-inner'>
                                                            <li className='leadership-card-details-1 leadership-card-details-text'>
                                                            Pioneer in Sri Lanka’s international higher education industry including medicine and health.
                                                            </li>
                                                            <li className='leadership-card-details-2 leadership-card-details-text'>
                                                            Philanthropic contributions to Healthcare and Education initiatives at scale. 
                                                            </li>
                                                            <li className='leadership-card-details-3 leadership-card-details-text'>
                                                            Signatory to UN Global Compact and Former Board Director of Sri Lanka Network of UN-GC.
                                                            </li>
                                                            <li className='leadership-card-details-4 leadership-card-details-text'>
                                                            Angel Investor in edu and health-tech.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='leadership-card-wrapper-2item'>
                                <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={dr_shakthi_kumar_profile} alt='dr_shakthi_kumar_profile' className='leadership-card-img-product'/>
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                        Dr. Shakthi Kumar
                                                        </div>
                                                       
                                                    </div>
                                                    <div className='leadership-linkdin'>
                                                    Former Worldwide Head of Health Solutions for AWS/Amazon Inc.
                                                    </div>
                                                    <div className='leadership-card-details'>
                                                        <ul className='leadership-card-details-inner'>
                                                            <li className='leadership-card-details-1 leadership-card-details-text'>
                                                            Top Voice in Healthcare and Life Sciences; Leadership roles at Amazon, Capgemini, BHS Group Intl., Coopers and Lybrand, LLP.
                                                            </li>
                                                            <li className='leadership-card-details-2 leadership-card-details-text'>
                                                            CxO; Board Member and Investor.
                                                            </li>
                                                            <li className='leadership-card-details-3 leadership-card-details-text'>
                                                            Co-founded several healthcare, tech and life sciences organizations.
                                                            </li>
                                                            <li className='leadership-card-details-4 leadership-card-details-text'>
                                                            Adjunct-faculty (MD/MPH) in Global Medicine and Health Policy.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={dr_dul_wickramanayake_profile} alt='inzu_kody_profile' />
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                            Dr. Dul Wickramanayake
                                                        </div>
                                                        <div className='leadership-card-position-inner'>
                                                            Cofounder/CEO
                                                        </div>
                                                    </div>
                                                    <div className='leadership-linkdin'>
                                                        <a href=''>
                                                            <img src={linkedin_icon} alt='linkedin_icon' />
                                                            <span>View Profile</span>
                                                        </a>
                                                    </div>
                                                    <div className='leadership-card-details'>
                                                        <ul className='leadership-card-details-inner'>
                                                            <li className='leadership-card-details-1 leadership-card-details-text'>
                                                                Medical Doctor + Process Engineer
                                                            </li>
                                                            <li className='leadership-card-details-2 leadership-card-details-text'>
                                                                Professional background in medicine, business and engineering.
                                                            </li>
                                                            <li className='leadership-card-details-3 leadership-card-details-text'>
                                                                Agile methodology and mindfulness practitioner.
                                                            </li>
                                                            <li className='leadership-card-details-4 leadership-card-details-text'>
                                                                16+ years in project management and customer happiness.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='leadership_position'>
                                                        MBBS, MBCS, MBA
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='leadership-cards-wrapper'>
                        <div className='leadership-cards-container'>
                            <div className='leadership-cards-inner'>
                                <div className='leadership-cards-title-wrapper'>
                                    <div className='leadership-cards-title-container'>
                                        <div className='leadership-cards-title'>
                                             Advisory Team (Product Strategy)
                                        </div>
                                    </div>
                                </div>
                                <div className='leadership-card-wrapper-2item'>
                                    <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={jamie_michaels_profile} alt='inzu_kody_profile'  className='leadership-card-img-product'/>
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                        Jamie Michaels
                                                        </div>
                                                    </div>
                                                    <div className='leadership-card-details leadership-card-details-product'>
                                                        <div className='leadership-card-details-inner'>
                                                            <div className='leadership-card-details-1'>
                                                            Head of Brand Strategy, X Corp. / Twitter, Inc. ; Vice Chair, Marketing and Communications Committee, North York General Hospital (Third largest hospital in Canada).

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={payal_shankar_profile} alt='inzu_kody_profile'  className='leadership-card-img-product'/>
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                        Payal Shankar
                                                        </div>
                                                    </div>
                                                    <div className='leadership-card-details leadership-card-details-product'>
                                                        <div className='leadership-card-details-inner'>
                                                            <div className='leadership-card-details-1'>
                                                            Associate Director, Johnson & Johnson - Janssen Pharmaceuticals, 15+ years in health-tech leadership. Executive training from Harvard Medical and Wharton.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='leadership-card-wrapper-2item'>
                                    <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={rudi_sellers_profile} alt='inzu_kody_profile'  className='leadership-card-img-product'/>
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                        Rudi Sellers
                                                        </div>
                                                    </div>
                                                    <div className='leadership-card-details leadership-card-details-product'>
                                                        <div className='leadership-card-details-inner'>
                                                            <div className='leadership-card-details-1'>
                                                            Associate Partner at IBM, Former Entrepreneur in Residence at University of Oxford, Oxford and Cambridge Alumni, Portfolio includes Vodafone, Barclaycard, and Experian.

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div className='leadership-card-wrapper'>
                                        <div className='leadership-card-container'>
                                            <div className='leadership-card-inner'>
                                                <div className='leadership-card-img'>
                                                    <img src={dr_vidushi_babber_md_profile} alt='inzu_kody_profile'  className='leadership-card-img-product'/>
                                                </div>
                                                <div className='leadership-all-details'>
                                                    <div className='leadership-card-name'>
                                                        <div className='leadership-card-name-inner'>
                                                        Dr. Vidushi Babber MD
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='leadership-card-details leadership-card-details-product'>
                                                        <div className='leadership-card-details-inner'>
                                                            <div className='leadership-card-details-1'>
                                                            Former Senior Associate Consultant - Mayo Clinic, Senior Leadership in Healthcare program development/management, Board Certified Physician/Psychiatrist.

                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default LeaderShipCard