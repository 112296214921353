import React, { useEffect, useState } from "react";
import "../Navbar/Navbar.css";
import { useNavigate } from "react-router-dom";
import { Link , useHistory} from "react-router-dom";
import main_banner_video from "../../video/main_banner_video.mp4";
import knightingale_logo from "../../img/knightingale_logo.png";
import menu from "../../img/menu.svg";
import main_banner_video_thumbnail from "../../img/Navabar/main_banner_video_thumbnail.jpg";
const Navbar = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [navbarColor, setNavbarColor] = useState(''); // Initial state for navbar color

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setNavbarColor('scrolled'); // Add class when scrolled beyond 60px
      } else {
        setNavbarColor(''); // Remove class when less than 60px
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    };
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };


  
  








  useEffect(() => {
    const activeItemIndex = localStorage.getItem('activeItem');
    if (activeItemIndex !== null) {
      const navbarItems = document.querySelectorAll('.navbar-item');
      navbarItems.forEach((item, index) => {
        if (index === parseInt(activeItemIndex)) {
          item.classList.add('activelink');
        } else {
          item.classList.remove('activelink');
        }
      });
    }
  }, []);

  const handleItemClick = (event, index) => {
    const navbarItems = document.querySelectorAll('.navbar-item');
    navbarItems.forEach(item => {
      item.classList.remove('activelink');
    });
    event.target.closest('.navbar-item').classList.add('activelink');
    localStorage.setItem('activeItem', index);
  };

  const handleClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSejhBcGyhl3O7adKyeOxXgPKKp01oqeJ2nVaknv5T5KemlSwQ/viewform?usp=sf_link', '_blank');
};

  return (
    <>
      <nav className="navbar-wrapper">
        <div className={`navbar-container ${navbarColor}`} id="navbar_contain">
          {isMobile ? (
            <div className="navbar-mobile-inner-container">
              <div className="navbar-mobile-inner">
                <div className="navbar-logo-wrapper">
                  <div className="navbar-logo-container">
                    <div className="navbar-logo" >
                      <a href="https://home.knightingale.ai/knland/">
                    <img src={knightingale_logo} alt="knightingale" />
                    </a>
                    </div>
                  </div>
                  {/* <div className="navbar-dropdown-container">
                    <div className="navbar-dropdown" onClick={handleToggle}>
                    <img src={menu} alt="knightingale" />
                    </div>
                  </div> */}
                </div>
                {/* {isToggled && (
                  <div className="navbar-items-wrapper">
                    <div className="navbar-mobile-items-container">
                      <div className="navbar-mobile-items">
                        <div className="navbar-mobile-item">
                        <Link to={"/leadership"}>Leadership</Link>
                        </div>
                        <div className="navbar-mobile-item">Pricing</div>
                        <div className="navbar-mobile-item">Contact Us</div>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          ) : (
            <div className="navbar-inner">
            <div className="navbar-logo-wrapper">
              <div className="navbar-logo-container">
                <div className="navbar-logo">
                <a href="https://home.knightingale.ai/knland/">
                  <img src={knightingale_logo} alt="knightingale" />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="navbar-items-wrapper">
              <div className="navbar-items-container">
                <div className="navbar-items">
                  <div className="navbar-item">
                    <Link to={"/leadership"} onClick={(e) => handleItemClick(e, 0)}>Leadership</Link>
                  </div>
                  <div className="navbar-item">
                    <Link to={"/platforms"} onClick={(e) => handleItemClick(e, 1)}>Platforms</Link>
                  </div>
                  <div className="navbar-item">
                    <Link to={"/whitepaper"} onClick={(e) => handleItemClick(e, 2)}>Whitepaper</Link>
                  </div>
                  <div className="navbar-item">
                    <Link to={"/contact"} onClick={(e) => handleItemClick(e, 3)}>Contact</Link>
                  </div>
                  <div className="navbar-item">
              <button className="banner-btn  banner-btn-1 banner-btn-nav-1" onClick={handleClick}>Get Early Access</button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          )}
        </div>
      </nav>

      {/* <div className="main-banner-wrapper">
        <div className="main-banner-container">
          <div className="main-banner-inner">
            <div className="banner-container banner-container-left-side">
              <div className="banner-inner  banner-container-left">
                <div className="banner-header">Hi I’m Knightingale,</div>
                <div className="banner-header-2">Your 24x7, LIVE</div>
                
                <div className="banner-title">Health Companion</div>
                <div className="banner-text">
                  the first humanoid AI companion who is changing paradigms in
                  digital health and wellness.
                </div>
                <div className="banner-wrapper-btn">
                <div className="banner-inner-btn">
                  <button className="banner-btn banner-btn-1">Ask Knightingale</button>
                  <button className="banner-btn banner-btn-2">Request Demo</button>
                </div>
                </div>
              </div>
            </div>
            <div className="banner-container  banner-container-right-side">
              <div className="banner-inner  banner-container-right">
                <div className="banner-inner-img">
                  <video width="100%" height="100%" playsinline loop autoPlay muted poster={main_banner_video_thumbnail} >
                    <source src={main_banner_video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Navbar;
