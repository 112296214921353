import React from "react";
import everythingImg from "../../img/Everything/everythiing.png";
import symptom_checker from "../../img/Capabilities/1.png";
import health_chat from "../../img/Capabilities/9.png";
import report_analyzer from "../../img/Capabilities/2.png";
import calorie_counter from "../../img/Capabilities/3.png";
import wellness_coach from "../../img/Capabilities/4.png";
import personalized_reminders_icon from "../../img/Capabilities/personalized_reminders_icon.png";
import sleep_helper from "../../img/Capabilities/5.png";
import workout_planner from "../../img/Capabilities/6.png";
import dailycheck from "../../img/Capabilities/dailycheck.png";
import goal_builder_icon from "../../img/Capabilities/goal_builder_icon.png";
import women_health from "../../img/Capabilities/7.png";
import care_reminder from "../../img/Capabilities/8.png";
import stress_regulator from "../../img/Capabilities/13.png";
import habit_changer from "../../img/Capabilities/10.png";
import sync_wearables from "../../img/Capabilities/11.png";
import live_ar_model from "../../img/Capabilities/12.png";
import telehealth from "../../img/Capabilities/16.png";
import care from "../../img/Capabilities/17.png";

import mobile_loop from "../../video/mobile_loop.mp4";

import "../Capabilities/Capabilities.css";

const Capabilities = () => {
    return (
        <div className="kn-section-wrpper">
            <div className="kn-section-container kn-section-container-capabilities ">
                <div className="kn-section-inner kn-section-inner-capabilities">
                    <div className="kn-section-header">
                        <div className="kn-section-title">Small Steps, Lasting Change</div>
                        <div className="kn-section-description ">Together, we'll focus on small, consistent habits that create big, lasting results over time. </div>
                    </div>

                    <div className="capabilities-wrapper">



                        <div className="capabilities-container">
                            <div className="capabilities-wrapper-row">

                                <div className="capabilities-btn-wrapper">
                                    <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={health_chat} alt="" />
                                            </div>
                                            <div className="capabilities-btn-name">
                                                <p>24/7
                                                    <br></br>Wellness Chat</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={symptom_checker} alt="" />

                                            </div>
                                            <div className="capabilities-btn-name">
                                                <div>Symptom
                                                    <br></br>Navigator</div>

                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={care} alt="" />
                                            </div>
                                            <div className="capabilities-btn-name">
                                                <p>Caring <br></br>Companion</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={calorie_counter} alt="" />
                                            </div>
                                            <div className="capabilities-btn-name">
                                                <p>Healthy
                                                    <br />Habits Builder</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={wellness_coach} alt="" />
                                            </div>
                                            <div className="capabilities-btn-name">
                                                <p>Holistic<br></br>Lifestyle Tips</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={care_reminder} alt="" />

                                            </div>
                                            <div className="capabilities-btn-name">
                                                <div>Medication<br></br>Reminder </div>

                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={report_analyzer} alt="" />

                                            </div>
                                            <div className="capabilities-btn-name">
                                                <div>Remote<br></br>Monitor</div>

                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={habit_changer} alt="" />

                                            </div>
                                            <div className="capabilities-btn-name">

                                                <div>Wearable<br></br>Integrator</div>

                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={dailycheck} alt="" />
                                            </div>
                                            <div className="capabilities-btn-name">
                                                <p>Science-Backed<br></br>Support</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={sleep_helper} alt="" />
                                            </div>
                                            <div className="capabilities-btn-name">
                                                <p>Personalized <br></br>Reminders</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={personalized_reminders_icon} alt="" />

                                            </div>
                                            <div className="capabilities-btn-name">
                                                <div>Personalized <br></br>Reminders</div>

                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={workout_planner} alt="" />
                                            </div>
                                            <div className="capabilities-btn-name">
                                                <p>Goal Builder <br></br>& Tracker</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={goal_builder_icon} alt="" />

                                            </div>
                                            <div className="capabilities-btn-name">
                                                <div>Goal Builder <br></br>& Tracker</div>

                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={telehealth} alt="" />
                                            </div>
                                            <div className="capabilities-btn-name">
                                                <p>Daily <br></br>Check-Ins</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={sync_wearables} alt="" />

                                            </div>
                                            <div className="capabilities-btn-name">
                                                <div>Habit<br></br>Changer</div>

                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="capabilities-btn-inner">
                                        <div className="capabilities-btn-container">
                                            <div className="capabilities-btn-img">
                                                <img src={live_ar_model} alt="" />

                                            </div>
                                            <div className="capabilities-btn-name">
                                                <div>LIVE AR<br></br>Mode</div>

                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Capabilities;
