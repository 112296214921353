import React from 'react'
import ContactCard from '../components/ContactCard/ContactCard'
import Navbar from '../components/Navbar/Navbar'

const ContactPage = () => {
  return (
    <>
    <Navbar />
 
   <ContactCard/>        
</>
  )
}

export default ContactPage