import React from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ChatWidget from "./components/ChatWidget/ChatWidget";
import HomePage from "./pages/HomePage";
import Leadership from "./pages/Leadership";
import PlatformsPage from "./pages/PlatformsPage";
import WhitepaperPage from "./pages/WhitepaperPage";
import ContactPage from "./pages/ContactPage";

// Corrected image path
const App = () => {
  const location = useLocation();

  // Define default meta tags
  let title = "Transform Your Wellness with Knightingale – Your AI-Powered Healthy Lifestyle Companion";
  let description =
    "Join Knightingale, your 24/7 AI wellness companion, for a guided journey to a healthier, happier life. Achieve sustainable wellness goals with personalized daily check-ins, habit-building support, and a step-by-step actionable wellness plan backed by science. Start your transformation today!";
  
  // Corrected image URL
  let imageUrl = "https://chatdbd.timetravel.vip/Knightingale_share_image.jpg";
  let currentUrl = `https://chatdbd.timetravel.vip/${location.pathname}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
     
      </Helmet>

      
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/leadership" element={<Leadership />} />
        <Route path="/platforms" element={<PlatformsPage />} />
        <Route path="/whitepaper" element={<WhitepaperPage />} />
        <Route path="/ContactPage" element={<ContactPage />} />
      </Routes>
    </>
  );
};

export default App;
