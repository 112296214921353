import React, { useState } from 'react';
import Modal from 'react-modal';
import { QRCodeCanvas } from 'qrcode.react'; // Using QRCodeCanvas
import meet_me_on_whatsapp_button from "../../img/Navabar/meet_me_on_whatsapp_button.png";

Modal.setAppElement('#root'); // Set the root element for accessibility

const WhatsappButton = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  
  const phoneNumber = '15165708615'; // Phone number in international format
  const message = "Hi Knightingale, \nI am ready for my wellness journey with you. 😊"; // Message to pre-fill
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`; // WhatsApp Web link with message for QR code

  const handleClick = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // If on mobile, open WhatsApp directly with pre-filled message
      window.open(`whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank');
    } else {
      // If on desktop, open popup with QR code
      setModalIsOpen(true);
    }
  };


  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <button className="banner-btn banner-btn-1" onClick={handleClick}>
        <img
          style={{ width: "100%", height: "auto" }}
          src={meet_me_on_whatsapp_button}
          alt="Meet me on WhatsApp button"
        />
      </button>

      {/* Popup modal for QR code */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="WhatsApp QR Code"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)', // Darker overlay for better focus
            zIndex: 10,
            transition: 'opacity 0.3s ease-in-out', // Smooth transition
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',  // Keeping the modal white for contrast
            borderRadius: '12px', // Slightly more rounded for a modern look
            padding: '25px',
            maxWidth: '340px', // A bit wider for breathing room
            width: '90%',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // More defined shadow for depth
            position: 'relative',
            transition: 'all 0.3s ease', // Smooth transition on modal appearance
          },
        }}
      >
        {/* Close button styled for better UX */}
        <button
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: '12px',
            right: '12px',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: '18px',
            cursor: 'pointer',
            color: '#333', // Slightly darker for better contrast
            transition: 'color 0.2s',
          }}
          onMouseEnter={(e) => e.target.style.color = '#d9534f'}  // Hover color effect for close button
          onMouseLeave={(e) => e.target.style.color = '#333'}
        >
          ✕
        </button>

        <h2 style={{
          textAlign: 'center',
          fontSize: '22px',
          fontWeight: '600',
          marginBottom: '15px', // Improved spacing below header
          color: '#333' // Darker text for readability
        }}>
          Scan to Open WhatsApp
        </h2>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '20px 0', // Reduced padding for better alignment
          padding: '10px',
        }}>
          <QRCodeCanvas value={whatsappUrl} size={160} /> {/* Slightly larger QR code */}
        </div>

        <p style={{
          textAlign: 'center',
          fontSize: '14px',
          color: '#777' // Lighter text for subtlety
        }}>
          Point your phone camera at the QR code to open WhatsApp
        </p>
      </Modal>

    </>
  );
};

export default WhatsappButton;
