import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import "../components/Navbar/Navbar";

import Capabilities from '../components/Capabilities/Capabilities'
import LeaderShipCard from '../components/LeaderShipCard/LeaderShipCard';

const Leadership = () => {
  return (
    <>
              <Navbar />
           
             <LeaderShipCard/>        
   </>
  )
}

export default Leadership