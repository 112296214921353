import React from 'react'
import "../Platform/Platform.css";
import platforms_desktop_banner from '../../video/platforms_desktop_banner.mp4'
import etern_graphic from '../../img/Platforms/etern_graphic.jpg'
import etern_metaverse_logo from '../../img/Platforms/etern_metaverse_logo.png'
import knightingale_graphic from '../../img/Platforms/knightingale_graphic.jpg'
import stageyou_graphic from '../../img/Platforms/stageyou_graphic.jpg'
import knightingale_logo_ai from '../../img/Platforms/knightingale_logo_ai.png'
import stageyou_logo from '../../img/Platforms/stageyou_logo.png'
import ProdutEco from '../ProdutEco/ProdutEco';

const Platform = () => {
    return (
        <div className='platforms-wrapper'>
            <div className='platforms-container'>
                <div className='platforms-inner'>
                    {/* <div className='platforms-video-wrapper'>
                        <div className='platforms-video-inner'>
                            <div className='platforms-video-container'>

                                <video width="100%" height="100%" playsinline loop autoPlay muted >
                                    <source src={platforms_desktop_banner} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className='platforms-video-title'>
                                    <div className='platforms-video-title-inner'>
                                        <h1>Platforms for the Future</h1>
                                        <div className="platform-inner">
                                            <h2> Discover our cutting-edge platforms, meticulously crafted for the evolving landscapes of the
                                                Metaverse,
                                                Artificial Intelligence, and Media. Prioritizing affordability and user-centric design, we're geared
                                                to
                                                seamlessly support your future needs.
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='platforms-cards-wrapper'>
                        <div className='platforms-cards-container'>
                            <div className='platforms-cards-inner'>
                                {/* <div className='platforms-card-wrapper'>
                                    <div class="right-side">
                                        <div class="right-side-inner-text">
                                            <img src={etern_metaverse_logo} class="platporm-site-logo platporm-site-logo-etern" alt="etern_metaverse_logo" />


                                            <h2> An ImmersiveTech Platform</h2>
                                            <p>
                                                Etern Metaverse is a cutting-edge digital universe where XR, AI and Web3 converge to
                                                create a network of virtual worlds. Ideal for work, learning, entertainment, and
                                                networking, it allows users to personalize avatars and explore via VR, desktop, or
                                                mobile. This platform is a hub for creativity, offering endless opportunities for users
                                                to design and grow their virtual spaces.
                                            </p>
                                        </div>
                                        <div class="right-side-inner-btn-wrapper">
                                            <div class="right-side-inner-btn-wrapper">
                                                <a href="https://eternmeta.com/" class="a-fill-btn-kps center-btn-kps ">Visit
                                                    EternMeta</a>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="left-side">
                                        <img src={etern_graphic} alt="etern_graphic" />
                                    </div>
                                </div>
                                <div className='platforms-card-wrapper'>
                                    <div className="solution-card-kps solution-card-kps-new  platform-card-kps-new knightingale-platform-card-kps-new">
                                        <div className="left-side">
                                            <img src={knightingale_graphic} alt="knightingale_graphic" />
                                        </div>
                                        <div className="right-side right-side-knightingale right-side-knightingale-new">
                                            <div className="right-side-inner-text ">
                                                <img src={knightingale_logo_ai} className="platporm-site-logo platporm-site-logo-knightingale" alt="knightingale_logo" />

                                                <h2>A stealth mode <br />HealthAI Platform</h2>
                                                <p>Undergoing deep learning and curation in Alpha mode.
                                                </p>
                                            </div>
                                            <div className="right-side-inner-btn-wrapper">
                                                <div className="right-side-inner-btn-wrapper">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='platforms-card-wrapper'>
                                    <div className="solution-card-kps solution-card-kps-new platform-card-kps-new revers-side">
                                        <div className="right-side">
                                            <div className="right-side-inner-text">
                                                <img src={stageyou_logo} className="platporm-site-logo platporm-site-logo-stageyou" alt="" />


                                                <h2>A Media-On-Demand Infrastructure</h2>
                                                <p>StageYou offers a fully managed media platform designed for content creators, producers,
                                                    and video entrepreneurs to effortlessly launch their own video platform and sell
                                                    subscriptions globally, without the need for a technical team. This Interactive
                                                    Media-On-Demand (ImOD) solution streamlines the process of reaching audiences worldwide.
                                                </p>
                                            </div>
                                            <div className="right-side-inner-btn-wrapper right-side-inner-btn-wrapper-new">
                                                <div className="right-side-inner-btn-wrapper">
                                                    <a href="https://www.stageyou.com/" className="a-fill-btn-kps center-btn-kps ">Visit
                                                        StageYou</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left-side">
                                            <img src="https://dejavu.quest/img/platforms/stageyou_graphic.jpg" alt="" />
                                        </div>
                                    </div>
                                </div> */}
                                <ProdutEco/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Platform