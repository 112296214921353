import React from "react";
import knightingale_pack_1_graphic from "../../img/ProdutEco/knightingale_pack_1_graphic.png";
import knightingale_pack_3_graphic from "../../img/ProdutEco/knightingale_pack_3_graphic.png";
import knightingale_pack_2_graphic from "../../img/ProdutEco/knightingale_pack_2_graphic.png";
import knightingale_pack_4_graphic from "../../img/ProdutEco/knightingale_pack_4_graphic.png";
import knightingale_welness_pack_logo from "../../img/ProdutEco/knightingale_welness_pack_logo.png";
import knightingale_pro_pack_logo from "../../img/ProdutEco/knightingale_pro_pack_logo.png";
import knightingale_xr_pack_logo from "../../img/ProdutEco/knightingale_xr_pack_logo.png";
import knightingale_guardian_pack_logo from "../../img/ProdutEco/knightingale_guardian_pack_logo.png";
// import calorie_counter from "../../img/ProdutEco/calorie-counter.png";
// import care_remainder from "../../img/ProdutEco/care-reminder.png";

import "../ProdutEco/ProdutEco.css";

const ProdutEco = () => {
    const handleClick = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSejhBcGyhl3O7adKyeOxXgPKKp01oqeJ2nVaknv5T5KemlSwQ/viewform?usp=sf_link', '_blank');
    };
    return (
        <div className="kn-section-wrpper">
            <div className="kn-section-container kn-section-container-ProdutEco ">
                <div className="kn-section-inner kn-section-inner-ProdutEco">
                    <div className="kn-section-header">
                        <div className="kn-section-title">Product Ecosystem</div>
                        <div className="kn-section-description kn-section-description-capabilities ">Knightingale builds integrated platforms, from web-based to immersive XR, all powered by humanized AI and personalization for intuitive user experiences.</div>
                    </div>

                    <div className="ProdutEco-wrapper">
                        <div className="ProdutEco-container">
                            <div className="ProdutEco-inner">
                                <div className="ProdutEco-container-row">
                                    <div className="ProdutEco-container-row-item-wrapper">
                                        <div className="ProdutEco-container-row-item ProdutEco-container-row-item-1">
                                            <div className="ProdutEco-container-row-item-img">
                                                <img src={knightingale_pack_1_graphic} alt="knightingale_blue" />
                                            </div>
                                            <div className="ProdutEco-container-row-item-detail-wrpper">
                                                <div className="ProdutEco-container-row-item-logo">
                                                    <img src={knightingale_welness_pack_logo} alt="knightingale-logo-pro" />
                                                </div>

                                                <div className="ProdutEco-container-row-item-description">
                                                Wellness guidance, <br/> preventive care and  companionship
                                                </div>
                                                {/* <div className="ProdutEco-container-row-item-status">
                                                <div className="ProdutEco-container-row-item-status-title">
                                                    Current:

                                                </div>
                                                <div className="ProdutEco-container-row-item-status-detail">
                                                    MVP in Closed Alpha
                                                </div>
                                            </div>
                                            <div className="ProdutEco-container-row-item-stage">
                                                <div className="ProdutEco-container-row-item-stage-title">
                                                    Next Stage:
                                                </div>
                                                <div className="ProdutEco-container-row-item-stage-detail">
                                                    Limited Beta Release and strategic partnerships.

                                                </div>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ProdutEco-container-row-item-wrapper">
                                        <div className="ProdutEco-container-row-item ProdutEco-container-row-item-4">
                                            <div className="ProdutEco-container-row-item-img">
                                                <img src={knightingale_pack_2_graphic} alt="knightingale_white" />

                                            </div>
                                            <div className="ProdutEco-container-row-item-detail-wrpper">
                                                <div className="ProdutEco-container-row-item-logo">
                                                    <img src={knightingale_pro_pack_logo} alt="knightingale-logo-xr" />
                                                </div>

                                                <div className="ProdutEco-container-row-item-description">
                                                Personalized chronic<br/> care, medication and mental health support.
                                                </div>
                                                {/* <div className="ProdutEco-container-row-item-status">
                                                <div className="ProdutEco-container-row-item-status-title">
                                                    Current:

                                                </div>
                                                <div className="ProdutEco-container-row-item-status-detail">
                                                    MVP in Development
                                                </div>
                                            </div>
                                            <div className="ProdutEco-container-row-item-stage">
                                                <div className="ProdutEco-container-row-item-stage-title">
                                                    Next Stage:
                                                </div>
                                                <div className="ProdutEco-container-row-item-stage-detail">
                                                    FDA pre-submission and regulatory preparation.

                                                </div>
                                            </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="ProdutEco-container-row">
                                    <div className="ProdutEco-container-row-item-wrapper">

                                        <div className="ProdutEco-container-row-item ProdutEco-container-row-item-2">
                                            <div className="ProdutEco-container-row-item-img">
                                                <img src={knightingale_pack_3_graphic} alt="knightingale_white" />

                                            </div>
                                            <div className="ProdutEco-container-row-item-detail-wrpper">
                                                <div className="ProdutEco-container-row-item-logo">
                                                    <img src={knightingale_guardian_pack_logo} alt="knightingale-logo-xr" />
                                                </div>

                                                <div className="ProdutEco-container-row-item-description">
                                                Continuous remote <br/> monitoring and smart care coordination. 
                                                </div>
                                                {/* <div className="ProdutEco-container-row-item-status">
            <div className="ProdutEco-container-row-item-status-title">
                Current:

            </div>
            <div className="ProdutEco-container-row-item-status-detail">
                MVP in Development
            </div>
        </div>
        <div className="ProdutEco-container-row-item-stage">
            <div className="ProdutEco-container-row-item-stage-title">
                Next Stage:
            </div>
            <div className="ProdutEco-container-row-item-stage-detail">
                FDA pre-submission and regulatory preparation.

            </div>
        </div> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="ProdutEco-container-row-item-wrapper" style={{borderColor:'#8397b7'}}>

                                        <div className="ProdutEco-container-row-item ProdutEco-container-row-item-3">
                                            <div className="ProdutEco-container-row-item-img">
                                                <img src={knightingale_pack_4_graphic} alt="knightingale_dark" />

                                            </div>
                                            <div className="ProdutEco-container-row-item-detail-wrpper">
                                                <div className="ProdutEco-container-row-item-logo">
                                                    <img src={knightingale_xr_pack_logo} alt="knightingale-logo-xr" />

                                                </div>
                                                <div className="ProdutEco-container-row-item-description">
                                                Immersive health <br/> companionship and mental wellbeing.
                                                </div>
                                                {/* <div className="ProdutEco-container-row-item-status">
                                                <div className="ProdutEco-container-row-item-status-title">
                                                    Current:
                                                </div>
                                                <div className="ProdutEco-container-row-item-status-detail">
                                                    MVP in WebXR
                                                </div>
                                            </div>
                                            <div className="ProdutEco-container-row-item-stage">
                                                <div className="ProdutEco-container-row-item-stage-title">
                                                    Next Stage:
                                                </div>
                                                <div className="ProdutEco-container-row-item-stage-detail">
                                                    Apple Vision Pro and Meta Quest compatibility.
                                                </div>
                                            </div> */}
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ProdutEco-container-request">
                                <div className="ProdutEco-container-request-detail">
                                    * Knightingale is not a substitute for professional health or medical advice. We adhere to FDA guidelines for general wellness products.
                                </div>
                                <div className="ProdutEco-container-request-btn">
                                    <div className="ProdutEco-container-request-btn-inner" onClick={handleClick}>
                                        Request Early Access
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProdutEco;
