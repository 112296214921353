import React from "react";
import everythingImg from "../../img/Everything/everythiing.png";
import done from "../../img/Everything/done.png";
import a_graphic from "../../img/Everything/1_graphic.png";
import b_graphic from "../../img/Everything/2_graphic.png";
import c_graphic from "../../img/Everything/3_graphic.png";
import knightingale_chat_mobile from "../../img/Everything/knightingale_chat_mobile.jpg";
import d_graphic from "../../img/Everything/4_graphic.png";
import mobile_video_loop_thumbnail from "../../img/Everything/mobile_video_loop_thumbnail.jpg";
import desktop_video_loop_thumbnail from "../../img/Everything/desktop_video_loop_thumbnail.jpg";
import mobile_video_loop from "../../video/mobile_video_loop.mp4";
import mobile_video_mobile_loop from "../../video/mobile_video_mobile_loop.mp4";
import heart_icon from "../../img/Navabar/heart_icon.png";
import "../Everything/Everything.css";

const Everything = () => {
  return (
    <div className="kn-section-wrpper">
      <div className="kn-section-container kn-section-container-everything ">
        <div className="kn-section-inner kn-section-inner-evrything">
          {/* <div className="kn-section-title">Meet Knightingale, Your Wellness Guide</div>
          <div class="kn-section-description ">Knightingale is designed to understand your unique health needs and guide you on a personalized path to better wellness. With our intelligent companion, you’ll receive tailored insights and continuous support to help you achieve your health goals.</div> */}
          <div className="everything-wrapper">

            <div className="everything-video-container">
              <div className="everything-video-inner">
                <div className="everything-video">
                  <video width="100%" height="100%"
                     playsInline
                     autoPlay
                     loop
                     muted
                    poster={desktop_video_loop_thumbnail}
                    className="desktop_vedio" >
                    <source src={mobile_video_loop} type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <video width="100%" height="100%"
                    playsInline
                    autoPlay
                    loop
                    muted
                    poster={mobile_video_loop_thumbnail}
                    className="mobile_vedio" 
                    >
                    <source src={mobile_video_mobile_loop} type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  {/* <img src={knightingale_chat_mobile} alt="done" style={{ width: '90%' }} /> */}
                </div>
              </div>
              {/* <div className="everything-video-circle"></div> */}

            </div>
            {/* <div className="everything-details"> */}
            {/* <div className="everyting-detail-title">
                Relate to any of these?
              </div> */}

            {/* <div className="everyting-detail-points"> */}

            {/* <ul className="everyting-detail-points-ul"> */}
            {/* <li className="everyting-detail-points-wrapper">

                    <div className="everyting-detail-point">
                      <div className="everyting-detail-point-name"><span>🍪</span> <span>Reaching for comfort foods when stressed or bored?</span></div>
                      <div className="everyting-detail-point-name"><span>⏰</span><span>Forgetting to prioritize your health in a busy day? </span> </div>
                      <div className="everyting-detail-point-name">🎯<span></span><span>Setting wellness goals but losing motivation quickly?</span>  </div>
                      <div className="everyting-detail-point-name">📱<span></span><span> Spending more time on screens than being active?</span> </div>
                      <div className="everyting-detail-point-name">🚶<span></span><span>Finding it hard to stay consistent with exercise?</span>  </div>
                      <div className="everyting-detail-point-name">💤<span></span><span>Skipping bedtime routines and waking up tired?</span>  </div>
                      <div className="everyting-detail-point-name">🤔<span></span><span>Unsure how daily choices affect your well-being?</span> </div>
                      <div className="everyting-detail-point-details">You don't have to navigate this journey alone. <img style={{ width: 30, height: 30, marginBottom: -8 }} src={heart_icon} alt='heart_icon' />
                      </div>
                    </div>
                  </li> */}
            {/* <li className="everyting-detail-points-wrapper">
                    
                    <div className="everyting-detail-point">
                    <div  className="everyting-detail-point-name"><img src={b_graphic}/>I'm Personalized:</div> 
                     <div  className="everyting-detail-point-details"> </div> 
                    </div>
                  </li>
                  <li className="everyting-detail-points-wrapper">
                    
                    <div className="everyting-detail-point">
                    <div  className="everyting-detail-point-name"><img src={c_graphic}/>I'm a Health Guru:</div> 
                     <div  className="everyting-detail-point-details"></div> 
                    </div>
                  </li>
                  <li className="everyting-detail-points-wrapper">
                    
                    <div className="everyting-detail-point">
                    <div  className="everyting-detail-point-name"><img src={d_graphic}/>I'll Be Your BFF:</div> 
                     <div  className="everyting-detail-point-details"></div> 
                    </div>
                  </li> */}

            {/* </ul> */}
            {/* </div> */}

            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Everything;
