import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import WhitepaperCard from '../components/WhitepaperCard/WhitepaperCard'

const WhitepaperPage = () => {
  return (
    <>
    <Navbar/>
    <WhitepaperCard />
    </>
  )
}

export default WhitepaperPage