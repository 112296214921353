import React, { useState } from 'react'
import Navbar from '../components/Navbar/Navbar'
import Everything from '../components/Everything/Everything'
import Humanized from '../components/Humanized/Humanized'
import Capabilities from '../components/Capabilities/Capabilities'
import ProdutEco from '../components/ProdutEco/ProdutEco'
import Frequently from '../components/Frequently/Frequently'
import BannerBlue from '../components/BannerBlue/BannerBlue'
import Footer from '../components/Footer/Footer'
import MainBanner from '../components/MainBanner/MainBanner'
import Agents from '../components/Agents/Agents'
import CookiePolicy from '../components/CookiePolicy/CookiePolicy'
import Trailer from '../components/Trailer/Trailer'

const HomePage = () => {
  const [cookieVisible, setCookieVisible] = useState(false);

  const handleVisibilityChange = (isVisible) => {
    setCookieVisible(isVisible);
  };
  return (
    <>
      <Navbar />
      <MainBanner />
      {/* <Features /> */}
      <Humanized />
      <Trailer />

      <Capabilities />
      <Everything />
      {/* <ProdutEco /> */}
      <Frequently />
      {/* <Agents/> */}
      <BannerBlue />
      <Footer />
      {/* <CookiePolicy onVisibilityChange={handleVisibilityChange} />
      <div>
        {cookieVisible && <div style={{height:'104px'}}></div>}
      </div> */}
    </>
  )
}

export default HomePage